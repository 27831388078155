<div class="user-management-header">
  <span class="user-management-header-title" translate>ADMIN_TITLE</span>
</div>


<div class="user-management-container">
  <div class="user-management-container-block-title" translate>USER_MANAGEMENT_TITLE</div>
  <div class="user-management-container-block-input">
    <mat-form-field class='user-management-container-username ap-component-loginInput'>
      <input [disabled]="fieldsDisabled" type="text" matInput placeholder="USERNAME" data-value="Text" [(ngModel)]="username" name="username">
    </mat-form-field>
    <span *ngIf="successMsg" class="user-management-container-success-msg" translate>{{successMsg}}</span>
    <span *ngIf="errorMsg" class="user-management-container-error-msg" translate>{{errorMsg}}</span>
    <app-spinner [show]="fieldsDisabled" size="small"></app-spinner>  
  </div>
  <div class="user-management-container-block-user-button-wrapper">
    <button class="user-management-container-block-user-button user-management-container-block-user-left-button" mat-raised-button [disabled]="fieldsDisabled" (click)="blockUser(true)"><span translate>BLOCK</span></button>
    <button class="user-management-container-block-user-button" mat-raised-button [disabled]="fieldsDisabled" (click)="blockUser(false)"><span translate>UNBLOCK</span></button>    
  </div>
</div>