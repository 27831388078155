import { Directive, ElementRef, EventEmitter, Inject, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[focus]'
})
export class FocusDirective implements OnInit {

  @Input('focus') focusEvent: EventEmitter<boolean>;

  constructor(@Inject(ElementRef) private element: ElementRef) {
  }

  ngOnInit() {
    this.focusEvent.subscribe(() => {
      (this.element.nativeElement as any)['focus'].apply(this.element.nativeElement, []);
    });
  }
}
