import { Injectable } from '@angular/core';
import { IReport } from '../models/report.type';
import { ReportService } from '../services/report.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class ReportManager {
  constructor(
    private reportService: ReportService,
    private logger: NGXLogger) {
  }

  public getReports(category, sortOption, sortDirection, successCallback, errorCallback) {
    const query = {
      category,
      sortOption,
      sortDirection
    };

    this.reportService.getReports(query).subscribe(reports => {
      successCallback(reports);
    }, (error) => {
      this.logger.error('[GO] Error retrieving reports', error);
      errorCallback(error);
    }, () => {

    });
  }

  public updateFavoriteReport(curReport: IReport, reports: IReport[]) {
    let reportPos;

    if (!curReport.favorite) {
      reportPos = reports.map(function (report) {
        return report.id;
      }).indexOf(curReport.id);
      this.reportService.setReportFavorite(reports[reportPos].name, true).subscribe(result => {
        reports[reportPos].favorite = true;
      }, error => {
        this.logger.error('[GO] Error toggling report favourite state', error);
      }, () => {

      });
    } else {
      reportPos = reports.map(function (report) {
        return report.id;
      }).indexOf(curReport.id);
      this.reportService.setReportFavorite(reports[reportPos].name, false).subscribe(result => {
        reports[reportPos].favorite = false;
      }, error => {
        this.logger.error('[GO] Error toggling report favourite state', error);
      }, () => {

      });
    }
  }

  public normalizeReports(reports) {
    if (!reports || reports.length === 0) {
      return [];
    }
    return reports.map(this.normalizeReport);
  }

  private normalizeReport(report) {
    return Object.assign({}, report, {
      date: new Date(),
      category: report.category.charAt(0).toUpperCase() + report.category.slice(1)
    });
  }
}
