import { ReportService } from './report.service';
import { ServerService } from './server.service';
import { AwsCredentialService } from './aws-credential.service';
import { DocumentService } from './document.service';
import { AuthenticateService } from './authenticate.service';
import { AuthGuardService } from './auth-guard.service';
import { UserService } from './user.service';
import { AppConfigService } from './app-config.service';
import { AppGuardService } from './app-guard.service';
import { InitConfigService } from './init-config.service';
import { NielsenService } from './nielsen.service';
import { GlobalService } from './global.service';
import { UtilsService } from '../utilities/utils';
import { QlikSessionService } from './qlik-session.service';
import { ReportManager } from '../managers/report-manager';
import { UserManager } from '../managers/user-manager';
import { BroadcastService } from './broadcast.service';
import { ProductService } from './product.service';

export const goServices = [
  ReportService,
  ServerService,
  AwsCredentialService,
  DocumentService,
  AuthenticateService,
  AuthGuardService,
  UserService,
  AppConfigService,
  AppGuardService,
  InitConfigService,
  NielsenService,
  GlobalService,
  UtilsService,
  QlikSessionService,
  ReportManager,
  UserManager,
  BroadcastService,
  ProductService
];
