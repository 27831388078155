import { Injectable } from '@angular/core';
import { BroadcastChannel } from 'broadcast-channel';
import { BroadcastMessage } from '../models/broadcast-message';
import { BroadcastMessageType } from '../models/broadcast-message-type';
import { UtilsService } from '../utilities/utils';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  private readonly channelName = 'precima-go';
  private readonly messageContext = 'message';
  private broadcastChannel: BroadcastChannel;

  constructor(private utils: UtilsService, private logger: NGXLogger) {
    this.init();
  }

  private init() {
    this.broadcastChannel = new BroadcastChannel(this.channelName);

    this.broadcastChannel.addEventListener(this.messageContext, this.loggingHandler);
    this.broadcastChannel.addEventListener(this.messageContext, this.vendorSwitchSuccessHandler);
  }

  public postMessage(message: BroadcastMessage) {
    this.logger.debug('[GO] Sent broadcast message', message);
    this.broadcastChannel.postMessage(message);
  }

  private loggingHandler = (message: BroadcastMessage) => {
    this.logger.debug('[GO] Received broadcast message', message);
  }

  private vendorSwitchSuccessHandler = (message: BroadcastMessage) => {
    if (message.type === BroadcastMessageType.vendorSwitchSuccess) {
      this.utils.contextRootRedirect();
    }
  }
}
