import { Routes } from '@angular/router';
import { DocumentContainerComponent } from './document-container/document-container.component';
import { HomeContainerComponent } from './home-container/home-container.component';
import { LoginContainerComponent } from './login-container/login-container.component';
import { LogoutContainerComponent } from './logout-container/logout-container.component';
import { MainContainerComponent } from './main-container/main-container.component';
import { PlanogramContainerComponent } from './planogram-container/planogram-container.component';
import { PreferencesContainerComponent } from './preferences-container/preferences-container.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AppGuardService } from './services/app-guard.service';
import { UserManagementContainerComponent } from './user-management-container/user-management-container.component';
import { DashboardComponent } from './navigation/dashboard/dashboard.component';
import { VersionComponent } from './version/version/version.component';
import { NiqAuthComponent } from './niq-auth/niq-auth.component';
import { NiqSsoComponent } from './niq-sso/niq-sso.component';
import { NielsenGuardService } from './services/nielsen-guard.service';
import { ProductComponent } from './product/product.component';
import { GoComponent } from './go/go.component';
import { AppConfigService } from './services/app-config.service';
import { MainContainerLegacyComponent } from './main-container-legacy/main-container-legacy.component';
import { AppCode } from './models/appOption/AppCode';

export const goRoutes: Routes = [
    { path: '', redirectTo: 'main', pathMatch: 'full', canActivate: [AuthGuardService] },
    { path: 'login', component: LoginContainerComponent },
    { path: 'logout', component: LogoutContainerComponent },
    { path: 'logout/:option', component: LogoutContainerComponent },
    { path: 'login/:option', component: LoginContainerComponent },
    { path: 'planogram/:file-key', component: PlanogramContainerComponent, canActivate: [AuthGuardService] },
    { path: 'version', component: VersionComponent },
    { path: 'niq-auth', component: NiqAuthComponent },
    { path: 'niq-sso', component: NiqSsoComponent },

    {
      path: 'main', component: MainContainerComponent, canActivate: [AuthGuardService, NielsenGuardService], resolve: { initialize: AppConfigService },
      children: [
        { path: '', redirectTo: 'my-dashboard', pathMatch: 'full' },
        {
          path: '', component: GoComponent,
          children: [
            { path: 'document', component: DocumentContainerComponent },
            { path: 'home', component: HomeContainerComponent, canActivate: [AppGuardService], data: { appCode: AppCode.Reports }, runGuardsAndResolvers: 'always' },
            { path: 'user-management', component: UserManagementContainerComponent },
            { path: 'preferences', component: PreferencesContainerComponent },
            { path: 'dashboard', component: DashboardComponent, canActivate: [AppGuardService], data: { appCode: AppCode.Dashboard } },
          ]
        },
        {
          path: '', component: ProductComponent,
          children: [
            { path: 'assortment', loadChildren: () => import('assortment-fe/src/app/assortment/assortment.module').then(m => m.AssortmentModule), canActivate: [AppGuardService], data: { appCode: AppCode.Assort },  runGuardsAndResolvers: 'always' },
            { path: 'data-portal', loadChildren: () => import('data-portal-fe/src/app/data-portal/data-portal.module').then(m => m.DataPortalModule), canActivate: [AppGuardService], data: { appCode: AppCode.DP }, runGuardsAndResolvers: 'always'},
            { path: 'insights-portal', loadChildren: () => import('insights-portal-fe/src/app/insights/insights.module').then(m => m.InsightsModule), canActivate: [AppGuardService], data: { appCode: AppCode.Insights }, runGuardsAndResolvers: 'always' },
            { path: 'my-dashboard', loadChildren: () => import('my-dashboard-fe/src/app/my-dashboard/my-dashboard.module').then(m => m.MyDashboardModule), canActivate: [AppGuardService], data: { appCode: AppCode.MyDashboard }, runGuardsAndResolvers: 'always' },
            { path: 'price', loadChildren: () => import('pricing-fe/src/app/pricing/pricing.module').then(m => m.PricingModule), canActivate: [AppGuardService], data: { appCode: AppCode.PriceNg }, runGuardsAndResolvers: 'always'},
            { path: 'promo', loadChildren: () => import('promo-fe/src/app/promo/promo.module').then(m => m.PromoModule), canActivate: [AppGuardService], data: { appCode: AppCode.Promo }, runGuardsAndResolvers: 'always'},
            { path: 'retail-dashboard', loadChildren: () => import('retail-dashboard-fe/src/app/retail-dashboard/retail-dashboard.module').then(m => m.RetailDashboardModule), canActivate: [AppGuardService], data: { appCode: AppCode.RetailDashboard }, runGuardsAndResolvers: 'always'},
            { path: 'supply-chain', loadChildren: () => import('supply-chain-fe/src/app/supply-chain/supply-chain.module').then(m => m.SupplyChainModule), canActivate: [AppGuardService], data: { appCode: AppCode.SC }, runGuardsAndResolvers: 'always' }
          ]
        }
      ]
    },
    { path: '**', redirectTo: 'main', canActivate: [AuthGuardService] }
];
