import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';
import { UserService } from '../services/user.service';
import { AppConfigService } from '../services/app-config.service';
import { Router } from '@angular/router';
import { UtilsService } from '../utilities/utils';
import { NGXLogger } from 'ngx-logger';
import { InitConfigService } from '../services/init-config.service';
import { PbSpinnerService } from 'precima-bootstrap/src/components';
import { LangService } from '../services/lang.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-logout-container',
  templateUrl: './logout-container.component.html',
  styleUrls: ['./logout-container.component.less']
})
export class LogoutContainerComponent implements OnInit {

  showSpinner = true;
  showPricing = false;
  showAA = false;
  activeApps = new Set();
  pricingGoDomain: string;
  aaGoDomain: string;

  constructor(private authenticationService: AuthenticateService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private appConfig: AppConfigService,
    private initConfig: InitConfigService,
    private utils: UtilsService,
    private logger: NGXLogger,
    private loadingSpinnerService: PbSpinnerService,
    private langService: LangService,
    @Inject('Constants') private constants: any,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
      this.loadingSpinnerService.loadingMessage = this.langService.instant('MSG.LOADING');
      this.loadingSpinnerService.addLoadingItemId('logout');

      if (!this.authenticationService.isAuthenticated()) {
        this.authenticationService.removeUserData();
        this.logger.debug('[GO] User is not authenticated. Redirecting to login.');
        this.goToLogin();
        return;
      }

      const params = this.route.snapshot.params;

      if (params['option'] && params['option'] === 'session-timeout') {
        this.logger.debug('[GO] Qlik session timeout');
        this.utils.contextRootRedirect();
        return;
      }

      // in case logout freezed which is likely to be caused by iframe loading errors.
      setTimeout(() => {
        this.authenticationService.removeUserData();
        this.ssoLogout();
      }, 10000);

      this.appConfig.init(() => {
        this.pricingGoDomain = this.appConfig.get().webConfig.pricingDomain + '/go';
        this.aaGoDomain = this.appConfig.get().webConfig.aaDomain + '/dashboard/go';

        if (this.utils.checkPriceAccess()) {
          this.showPricing = true;
          this.activeApps.add(this.pricingGoDomain);
          // this.onIframeLoad(this.pricingGoDomain, 0);
        }

        if (this.utils.checkAAAccess() || this.utils.checkCustomProductListAccess()) {
          this.showAA = true;
          this.activeApps.add(this.aaGoDomain);
          // this.onIframeLoad(this.aaGoDomain, this.showPricing ? 1 : 0);
        }

        const checkApps = setInterval(() => {
          if (this.activeApps.size === 0) {
            this.authenticationService.removeUserData();
            this.ssoLogout();
            clearInterval(checkApps);
          }
        }, 100);
      }, (error) => {
        this.logger.error('[GO] Error initializing web config', error);
        this.authenticationService.removeUserData();
      });
  }

  public appLoad() {
    if (this.showPricing) {
      this.onIframeLoad(this.pricingGoDomain, 0);
    }

    if (this.showAA) {
      this.onIframeLoad(this.aaGoDomain, this.showPricing ? 1 : 0);
    }
  }

  private onIframeLoad(appName, index) {
    setInterval(() => {
      if (this.activeApps.has(appName)) {
        this.logger.debug('[GO] Posting logout message', appName, index);
        const iframe = window.frames[index];
        const msg = JSON.stringify({ key: 'logout', data: '' });
        iframe.postMessage(msg, appName);
        this.activeApps.delete(appName);
      }
    }, 2000);
  }

  onLoadError() {
    this.authenticationService.removeUserData();
    this.goToLogin();
  }

  private goToLogin() {
    this.loadingSpinnerService.removeLoadingItemId('logout');
    this.showSpinner = false;

    if (!this.initConfig.get().niqSsoUri) {
      this.logger.error('[GO] niq-sso-uri not configured');
      return;
    }

    window.location.href = this.initConfig.get().niqSsoUri;
  }

  private ssoLogout() {
    this.loadingSpinnerService.removeLoadingItemId('logout');
    this.showSpinner = false;

    if (!this.initConfig.get().niqLogoutLink) {
      this.logger.error('[GO] niq-logout-link not configured');
      return;
    }

    window.location.href = this.initConfig.get().niqLogoutLink;
  }

}
