import { Injectable, Inject } from '@angular/core';
import { AwsCredentialService } from './aws-credential.service';
import { ServerService } from './server.service';
import { AppConfigService } from './app-config.service';
import { getApiUrl } from '../utilities/env-utils';
import { NGXLogger } from 'ngx-logger';

declare var AWS: any;

@Injectable()
export class DocumentService {

  constructor(@Inject('Environment') private environment: any,
              private awsCredentialService: AwsCredentialService,
              private serverService: ServerService,
              private appConfig: AppConfigService,
              private logger: NGXLogger) {
  }

  public loadDocuments(bucketConfig, successCallback, errorCallback) {
    this.awsCredentialService.getAwsCredentials(bucketConfig, (res) => {
      this.listFiles(bucketConfig, res, successCallback, errorCallback);
    }, () => {
      this.logger.error('[GO] Error getting AWS creds');
      errorCallback();
    });
  }

  public listFiles(bucketConfig, awsCreds, successCallback, errorCallback) {
    if (!awsCreds) {
      this.logger.error('[GO] AWS creds null');
      errorCallback();
      return;
    }
    const bucket = new AWS.S3();

    bucket.config.credentials =
      new AWS.Credentials(awsCreds.AccessKeyId,
        awsCreds.SecretAccessKey,
        awsCreds.SessionToken);

    const params = {
      Bucket: bucketConfig.bucket,
      Prefix: bucketConfig.folder_prefix
    };

    bucket.listObjects(params, (err, data) => {
      if (err) {
        this.logger.error('[GO] Error getting list of documents');
        errorCallback();
        return;
      }

      if (!data.Contents) {
        this.logger.error('[GO] No content in list of documents');
        errorCallback();
        return;
      }

      successCallback(data);
    });
  }

  openDocument(bucketConfig, file, openInNewTab) {
    this.openDocumentWithKey(bucketConfig, file.key, openInNewTab);
  }

  openDocumentWithKey(bucketConfig, fileKey, openInNewTab) {
    this.logger.debug('[GO] Open document with file key:', fileKey);
    this.awsCredentialService.getAwsCredentials(bucketConfig, (res) => {
      const bucket = new AWS.S3();
      bucket.config.credentials =
        new AWS.Credentials(res.AccessKeyId,
          res.SecretAccessKey,
          res.SessionToken);

      bucket.getSignedUrl('getObject', {Bucket: bucketConfig.bucket, Key: fileKey}, (err, url_bucket) => {
        if (err) {
          this.logger.error('[GO] Error getting signed URL');
          return;
        }
        let tabStatus = '_self';
        if (openInNewTab) {
          tabStatus = '_blank';
        }

        window.open(url_bucket, tabStatus);
      });
    }, () => {
      this.logger.error('[GO] Error getting AWS creds');
      return;
    });
  }

  public getTrainingSSOToken() {
    return this.serverService.get(getApiUrl() + '/get-sso-token', {});
  }

  public getTrainingAccountExist(username) {
    return this.serverService.get(getApiUrl() + '/user-exist-lms?username=' + username, {});
  }
}
