<div class="document-container">
  <div class="document-container-title-bar">
    <div translate class="document-container-title">TRAINING_MATERIALS</div>
  </div>

  <div class="document-container-spinner-wrapper"  *ngIf="loadingFiles">
    <app-spinner [show]="true"></app-spinner>
  </div>
  <div class="document-container-content" *ngIf="!loadingFiles">
    <div *ngIf="trainingOption === constants.TRAINING_OPTIONS.PDF">
      <!--<div translate class="document-container-training-title">TRAINING_MATERIALS</div>-->
      <mat-list>
        <mat-list-item *ngFor="let file of files" >
          <a target="_blank" (click)="openDocument(file)"><h4>{{file.name}}</h4></a>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>