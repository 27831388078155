<div class="preferences-container">
  <div class="preferences-item">
    <div class="preferences-item-row">
      <span translate>EXPAND_MAIN_NAVIGATION</span>
      <mat-slide-toggle (change)="toggleSideNavExpanded()" [(ngModel)]="sideNavExpanded"></mat-slide-toggle>
    </div>
    <div class="preferences-item-row">
      <div class="preferences-item-row-image side-nav-close"></div>
      <div class="preferences-item-row-image side-nav-open"></div>
    </div>
  </div>
  <div class="preferences-item">
    <div class="preferences-item-row">
      <span translate>EXPAND_SECONDARY_NAVIGATION</span>
      <mat-slide-toggle (change)="toggleSecondarySideNavExpanded()" [(ngModel)]="secondarySideNavExpanded"></mat-slide-toggle>
    </div>
    <div class="preferences-item-row">
      <div class="preferences-item-row-image secondary-side-nav-close"></div>
      <div class="preferences-item-row-image secondary-side-nav-open"></div>
    </div>
  </div>
  <div class="preferences-item" [hidden]="!showReports">
    <div class="preferences-item-row">
      <span translate>SHOW_AS_TILE</span>
      <mat-slide-toggle (change)="toggleReportView()" [(ngModel)]="gridView"></mat-slide-toggle>
    </div>
    <div class="preferences-item-row">
      <div class="preferences-item-row-image grid-view-false"></div>
      <div class="preferences-item-row-image grid-view-true"></div>
    </div>
  </div>
</div>
