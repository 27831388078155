import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class QlikSessionService {
  isVendorSwitchOn = false;

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfigService
  ) { }

  async deleteQlikProxySessions(): Promise<any> {
    const qlikJwtUrls = this.appConfig.get().webConfig.qlikJwtUrls;

    if (!qlikJwtUrls) {
      return;
    }

    const promises = [];

    for (const url of qlikJwtUrls) {
      promises.push(this.deleteProxySessionForApp(url + '/qps/user'));
    }

    return Promise.all(promises);
  }

  deleteProxySessionForApp(jwtUrl): Promise<any> {
    const url = jwtUrl + '?t=' + (new Date()).getTime();
    const headers = new HttpHeaders();
    const token = localStorage.getItem('id_token');
    headers.append('Authorization', 'Bearer ' + token);
    headers.append('Content-Type', 'application/json');
    return (this.httpClient.delete(url, { headers: headers, withCredentials: true })).toPromise();
  }

  vendorSwitchOn(isVendorSwitchingOn: boolean = false) {
    if (isVendorSwitchingOn) {
      this.isVendorSwitchOn = true;
    } else {
      localStorage.removeItem('vendorSwitching');
    }
  }

  getIsVendorSwitchOn() {
    return this.isVendorSwitchOn;
  }
}
