<div class="dialog-container">
        <div class="dialog-container-header">
                <h2 mat-dialog-title translate class="dialog-container-header-title">SUPPORT_CONTACT_MESSAGE_HEADER</h2>
        </div>
        <mat-dialog-content class="dialog-container-content">
            <div translate >{{supportContactMessage}}</div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="align-right">
                <button matraised-button (click)="dialogRef.close()" class="ok-button"><span translate>SUPPORT_CONTACT_DIALOG_OK</span></button>
            </div>
        </mat-dialog-actions>
    </div>