import { AppCode } from './AppCode';
import { PbLoadingSpinnerApp } from 'precima-bootstrap/src/components';

export interface AppOption {
    /**
     * @deprecated use GoNavigationItem instead
     */
    title: string;
    code: AppCode;
    label: string;
    selected: boolean;
    iconPath: string;
    url: string;
    spinnerApp: PbLoadingSpinnerApp;
}

export function getPathColorful(path: string): string {
    return path.slice(0, -10) + '.svg';
}

export function getPathBlack(path: string): string {
  return path.slice(0, -10) + '-black.svg';
}

export function getPathWhite(path: string): string {
  return path.slice(0, -10) + '-white.svg';
}
