import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.less']
})
export class VersionComponent implements OnInit {

  constructor(@Inject('Environment') public env: any) { }

  ngOnInit() {
  }

}
