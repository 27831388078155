import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(private logger: NGXLogger) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const withCredentials = req.method !== 'OPTIONS' && req.url.indexOf('localhost') < 0;

    if (withCredentials) {
      this.logger.debug('[GO] Interceptor with credentials: ' + req.url);

      req = req.clone({
        withCredentials: withCredentials
      });
    }

    return next.handle(req);
  }
}
