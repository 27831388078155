import { getClientFromUrl } from '../app/utilities/env-utils';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  apiEndpoint: 'http://localhost:5000/api',
  client: getClientFromUrl(),
  runLocal: false,
  webConfig: {},
  logger: {
    level: NgxLoggerLevel.LOG
  },
  bambooBuildNumber: '722',
  bambooBuildTimeStamp: '2024-09-06T08:41:38.261-05:00',
  baseAppURL: 'https://uat.connect.nielseniq.io',
  csAppUrl: '/csapps',
  csAppUrls: {
    collectionsUrl: 'collections',
    appInfo: '/appinfo',
  }
};
