import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppCode } from '../../models/appOption/AppCode';
import { AppOption } from '../../models/appOption/AppOption';
import { MenuMode } from '../menu/menu.component';
import { GlobalService } from '../../services/global.service';
import { NGXLogger } from 'ngx-logger';
import { MatMenuTrigger } from '@angular/material/menu';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.less']
})
export class SupportComponent implements OnInit {
  @Input() mode = MenuMode.Grid;
  @Input() opened = true;
  @Input() supportItems = [];
  @Input() hasTraining = false;
  @Output() onSelect = new EventEmitter<any>();
  @Output() onSupportMenuClose = new EventEmitter<any>();
  @ViewChild(MatMenuTrigger) supportMenuTrigger: MatMenuTrigger;

  constructor(
    private globalService: GlobalService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.addSupportMenuItems();
  }

  private addSupportMenuItems() {
    this.supportItems.push({
      title: 'CONTACT_US',
      code: AppCode.Contact,
      selected: false,
      iconPath: '',
      url: ''
    } as AppOption);

    this.globalService.obsTraningLink()
      .pipe(take(2))
      .subscribe(
        res => {
          this.hasTraining = res;
          if (this.hasTraining) {
            this.supportItems.push({
              title: 'TRAINING',
              code: AppCode.Training,
              selected: false,
              iconPath: '',
              url: ''
            } as AppOption);
          }
        },
        error => this.logger.error('[GO] Error fetching training link', error)
      );
  }

  select($event) {
    this.onSelect.emit($event);
  }

  mouseLeave($event) {
    if ($event['movementX'] === 0 && $event['movementY'] === 0) { return; }
    this.supportMenuTrigger.closeMenu();
    this.onSupportMenuClose.emit($event);
  }
}
