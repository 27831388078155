import { Component, EventEmitter, OnInit } from '@angular/core';
import { ONBOARD_ITEMS } from '../../models/onboard';
import { DatetimeService } from '../../services/datetime.service';
import { GlobalService } from '../../services/global.service';
import { LangService } from '../../services/lang.service';
import { UtilsService } from '../../utilities/utils';
import { take } from 'rxjs/internal/operators/take';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-welcome-board',
  templateUrl: './welcome-board.component.html',
  styleUrls: ['./welcome-board.component.less']
})
export class WelcomeBoardComponent implements OnInit {
  datetime: Date;
  username = '';
  monthKey = '';
  onboardItems = [];
  isEuroFormat = false;
  onLangChange = new EventEmitter<any>();
  hasTraining = false;

  constructor(
    private service: UtilsService,
    private datetimeService: DatetimeService,
    private globalService: GlobalService,
    private langService: LangService,
    private logger: NGXLogger) {
  }

  ngOnInit() {
    this.username = this.service.getUserFullName();
    this.setupClock();
    this.setMonthKey();
    this.fetchTrainingLink();
    this.watchLangChange();
  }

  ngOnDestroy() {
    this.datetimeService.stopClockInterval();
  }

  setupClock(): void {
    this.datetime = new Date();
    this.datetimeService.subscribeDatetime(
      datetime => this.datetime = datetime,
      error => this.logger.error('[GO] Error setting clock', error)
    );
  }

  setMonthKey() {
    this.monthKey = this.datetime.toString().substring(4, 7).toUpperCase();
  }

  onSelect(trainingName: any) {
    this.globalService.emitOpenTraining(trainingName);
  }

  watchLangChange() {
    this.onLangChange = this.langService.onLangChange();
    this.onLangChange.subscribe(res => { this.isEuroFormat = res.lang == 'nl' });
  }

  private fetchTrainingLink() {
    this.globalService.obsTraningLink()
      .pipe(take(2))
      .subscribe(
        res => {
          this.hasTraining = res;
          if (this.hasTraining) {
            this.onboardItems = ONBOARD_ITEMS;
          }
        },
        error => this.logger.error('[GO] Error fetching training link', error)
      );
  }
}
