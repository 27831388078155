import { Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { HelpDialData, HelpDialogComponent } from '../common/dialogs/help-dialog/help-dialog.component';
import { UserManager } from '../managers/user-manager';
import { AppConfigService } from '../services/app-config.service';
import { AuthenticateService } from '../services/authenticate.service';
import { GlobalService } from '../services/global.service';
import { InitConfigService } from '../services/init-config.service';
import { LangService } from '../services/lang.service';
import { UserService } from '../services/user.service';
import { PbLoadingSpinnerApp, PbSpinnerService } from 'precima-bootstrap/src/components';
import { filter } from 'rxjs/operators';
import { NielsenService } from '../services/nielsen.service';
import { UtilsService } from '../utilities/utils';
import { UserVendorOptionDialogComponent } from '../common/dialogs/vendor-group-switching-dialog/vendor-group-switching-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppCode } from '../models/appOption/AppCode';
import { ServerService } from '../services/server.service';
import { DocumentService } from '../services/document.service';
import { Subscription } from 'rxjs/Rx';
import { TRAINING_OPTIONS } from '../constants';
import { DialogSupportContact } from '../common/dialogs/support-contact-dialog/support-contact-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { GoInitService, GoIntlService, GoNavigationService } from 'precima-bootstrap/src/components/services/go';
import { getClientCode } from '../utilities/env-utils';
import { BroadcastService } from '../services/broadcast.service';
import { BroadcastMessageType } from '../models/broadcast-message-type';
import { ProductService } from '../services/product.service';

declare let ChurnZero: any;

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.less']
})
export class MainContainerComponent implements OnInit, OnDestroy {

  sidenavOpened = true;
  moduleSidenavOpened = false;
  moduleMenuCloseEvent: EventEmitter<any>;
  isModuleLoading = false;
  activeModule: any;
  spinnerMode = 'bothOpened';
  loadingApp = PbLoadingSpinnerApp.NONE;
  userGroups: String[] = [];
  userGroup: String;
  pbOverlayEnabled: boolean;
  private vendorSwitchingLoadingItemId = 'vendor-switching';
  private navigationSubscription: Subscription;

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.decrementSessionCounter();
    if (0 === parseInt(localStorage.getItem('sessionCounter'))) {
      localStorage.removeItem('reportingServers');
    }
  }

  constructor(private userManager: UserManager,
    private appConfig: AppConfigService,
    private initConfig: InitConfigService,
    private userService: UserService,
    private authenticateService: AuthenticateService,
    public globalService: GlobalService,
    private langService: LangService,
    private logger: NGXLogger,
    public dialog: MatDialog,
    private router: Router,
    private nielsenService: NielsenService,
    private utils: UtilsService,
    private documentService: DocumentService,
    private serverService: ServerService,
    private productService: ProductService,
    private loadingSpinnerService: PbSpinnerService,
    private translateService: TranslateService,
    private broadcastService: BroadcastService,
    private goInitService: GoInitService,
    private goIntl: GoIntlService,
    private goNavigationService: GoNavigationService,
    @Inject('Environment') private environment: any,
    @Inject('Constants') private constants: any) {
    this.globalService.moduleLoading$.subscribe(isModuleLoading => {
      this.toggleLoading(isModuleLoading);
      this.loadingSpinnerService.loadingMessage = this.langService.instant('MSG.LOADING');
      this.loadingSpinnerService.addLoadingItemId('MSG.LOADING');
    });
  }

  ngOnInit() {
    this.subscribeHubCheck();
    this.checkUserStatus();
    this.watchRouteChange();

    this.subscribeNavPreference();
    this.fetchNavPreference();
    this.fetchLangPreference();
    this.loadSupport();
    this.loadApps();
    this.subscribeGoNavigation();

    if (this.utils.isVendorSwitchingAllowed()) {
      this.processVendorSwitchingConditions();
    }

    this.initChurnZero();
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  private initChurnZero() {
    if (this.appConfig.get().webConfig.churnZeroEnabled) {
      ChurnZero.push(['setAppKey', this.appConfig.get().webConfig.churnZeroAppKey]);
      ChurnZero.push(['setContact', this.appConfig.get().webConfig.churnZeroAccountExternalId, this.utils.getUserEmail()]);
    }
  }

  processVendorSwitchingConditions() {
    this.toggleVendorSwitchingLoader(true);
    this.isModuleLoading = true;
    let prevGroupSelected = localStorage.getItem('userGroup');
    const username = localStorage.getItem('username');
    this.populateUserGroups();
    this.logger.debug('[GO] Previous vendor group selection:', prevGroupSelected);

    if (prevGroupSelected && !prevGroupSelected.includes('null')) {
      const usernameAndGroup = prevGroupSelected.split(',');
      if (usernameAndGroup[0].toLowerCase() === username.toLowerCase()) {
        prevGroupSelected = usernameAndGroup[1];
      } else {
        prevGroupSelected = null;
      }
    }

    if (this.userGroups.length === 1) {
      this.userGroup = this.userGroups[0];
      this.userService.changeDisplayGroup(this.userGroups[0]);
      this.logger.debug('[GO] User belongs to only one vendor group. Previous selection ignored.');
      this.isModuleLoading = false;
      this.toggleVendorSwitchingLoader(false);
      return;
    }

    if (this.isPreviousSelectionValid(username, prevGroupSelected)) {
      if (prevGroupSelected !== this.utils.getChosenGroup()) {
        if (this.appConfig.get().webConfig.ssoEnabled) {
          return this.authenticateService.silentLogin(username,  prevGroupSelected, () => {
            localStorage.removeItem('waitSwitch');
            this.utils.contextRootRedirect();
            this.broadcastService.postMessage({
              type: BroadcastMessageType.vendorSwitchSuccess,
              message: 'Switched to previously selected vendor group: ' + prevGroupSelected
            });
            this.toggleVendorSwitchingLoader(false);
          }, (error) => {
            this.logger.error('[GO] Error with vendor login', error);
            this.toggleVendorSwitchingLoader(false);
          });

        } else {
          this.logger.debug('[GO] This version only supports silent login.')
          this.toggleVendorSwitchingLoader(false);
        }
      } else {
        this.userGroup = this.userGroups[0];
        this.userService.changeDisplayGroup(this.userGroups[0]);
        this.logger.debug('[GO] Previous vendor group selection is already present in user token');
        this.toggleVendorSwitchingLoader(false);
      }
    } else {
      this.logger.debug('[GO] Previous vendor group selection is not valid');
      this.toggleVendorSwitchingLoader(false);
      this.openVendorSwitchingDialog();
    }

    this.isModuleLoading = false;
  }

  private openVendorSwitchingDialog() {
    const dialogRef = this.dialog.open(UserVendorOptionDialogComponent, {
      data: {
        userGroups: this.userGroups,
        userGroup: this.userGroup,
        password: null,
        showPasswordInput: false,
        ssoEnabled: this.appConfig.get().webConfig.vendorSwitching
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.logger.debug('[GO] Group:', data.userGroup);
        const username = localStorage.getItem('username');
        if (!data) {
          return;
        }
        this.userGroup = data;
        this.userService.changeDisplayGroup(data);
      }
    });
  }

  private isPreviousSelectionValid(username, prevGroupSelected) {
    if (username !== this.utils.getUserNameFromToken()) {
      return false;
    }

    if (prevGroupSelected === 'null' || !prevGroupSelected) {
      return false;
    }

    for (let i = 0; i < this.userGroups.length; i++) {
      if (this.userGroups[i] === prevGroupSelected) {
        return true;
      }
    }

    return false;
  }

  private populateUserGroups() {
    const groups = this.utils.getVendorAccess();
    const array = groups.split(',');
    if (array && array.length) {
      this.userGroups = this.userGroups.concat(array);
    }
  }

  private fetchLangPreference() {
    this.userManager.getUserPreference('lang', this.langService.lang, res => {
      const value = res['lang'];
      if (this.langService.isValid(value)) {
        this.langService.use(value);
        this.toggleLanguageChange(value);
      }
    }, error => {
      this.logger.error('[GO] Error getting user preference', error)
    });
  }

  private fetchNavPreference() {
    this.userManager.getUserPreference('sideNavExpanded', 'true',
      (userPreferences) => {
        this.sidenavOpened = userPreferences['sideNavExpanded'] === 'true';
        this.setPrecimaGoMenuOpened();
        this.incrementSessionCounter();
      },
      () => {
        this.setPrecimaGoMenuOpened();
        this.incrementSessionCounter();
      });
  }

  private subscribeNavPreference() {
    this.userService.preferenceChanged$
      .subscribe(
        preference => {
          if (preference.key === 'sideNavExpanded') {
            this.sidenavOpened = preference.value;
            this.setPrecimaGoMenuOpened();
          }
        });
  }

  private watchRouteChange() {
    this.router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
          const startIndex = event.url.lastIndexOf('main/') + 5;
          const endIndex = event.url.lastIndexOf('/');
          const applicationPath = event.url.substring(startIndex, endIndex > startIndex ? endIndex : undefined);
          this.loadingApp = this.constants.PATH_MAP[applicationPath];
        });
  }

  toggleLanguageChange(event: string) {
    if (this.activeModule && this.activeModule.hasOwnProperty('langSelected')) {
      this.activeModule.setLanguageChange(event);
    }
  }

  setModuleMenuOpened() {
    if (this.activeModule && this.activeModule.hasOwnProperty('moduleMenuOpened')) {
      this.activeModule.setModuleMenuOpened(this.moduleSidenavOpened);
    }
  }

  onActivate(componentRef) {
    this.activeModule = componentRef;
    this.activateModule();
  }

  activateModule() {
    this.userManager.getUserPreference('lang', '',
      res => {
        const value = res['lang'];
        if (this.langService.isValid(value)) {
          this.langService.use(value);
          this.toggleLanguageChange(value);
        }
      },
      error => {
        this.logger.error('[GO] Error getting user preference', error);
      });

    this.userManager.getUserPreference('secondarySideNavExpanded', 'true', (userPreferences) => {
      this.moduleSidenavOpened = this.initConfig.get().showSecondaryNav && userPreferences['secondarySideNavExpanded'] === 'true';
      this.setSpinnerMode();
      this.setModuleMenuOpened();
      this.setPrecimaGoMenuOpened();
    }, () => {
      this.setModuleMenuOpened();
      this.setPrecimaGoMenuOpened();
    });

    if (this.activeModule && this.activeModule.hasOwnProperty('moduleMenuCloseEvent')) {
      this.moduleMenuCloseEvent = new EventEmitter<any>();
      this.activeModule.setModuleMenuCloseEvent(this.moduleMenuCloseEvent);
      this.moduleMenuCloseEvent.subscribe(() => {
        this.moduleSidenavOpened = false;
        this.setSpinnerMode();
      });
      this.toggleLoading(false);
    }
  }

  setPrecimaGoMenuOpened() {
    if (this.activeModule && this.activeModule.hasOwnProperty('precimaGoMenuOpened')) {
      this.activeModule.setPrecimaGoMenuOpened(this.sidenavOpened);
    }
  }

  incrementSessionCounter() {
    const openSessionCounterStr = localStorage.getItem('sessionCounter');

    if (!openSessionCounterStr) {
      localStorage.setItem('sessionCounter', '1');
    } else {
      let openSessionCounter = parseInt(openSessionCounterStr);
      openSessionCounter++;
      localStorage.setItem('sessionCounter', openSessionCounter.toString());
    }
  }

  decrementSessionCounter() {
    const openSessionCounterStr = localStorage.getItem('sessionCounter');
    if (openSessionCounterStr) {
      let openSessionCounter = parseInt(openSessionCounterStr);
      openSessionCounter--;
      localStorage.setItem('sessionCounter', openSessionCounter.toString());
    }
  }

  checkUserStatus() {
    setInterval(() => {
      if (window.location.href.indexOf('login') < 0) {
        this.userService.checkUserStatus(localStorage.getItem('userFullId')).subscribe(res => {
          if (res.status) {
            this.authenticateService.logout('/logout');
          }
        }, error => {
          this.logger.error('[GO] Error checking user status', error);
        });
      }
    }, 600000);
  }

  toggleLoading(isModuleLoading) {
    this.isModuleLoading = isModuleLoading;
    this.setSpinnerMode();
  }

  private openDialog(): void {
    this.dialog.open(HelpDialogComponent, {
      disableClose: true,
      data: this.dialData
    });
  }

  private subscribeHubCheck(): void {
    this.globalService.obsHubCheck().subscribe(
      res => { if (res) { this.openDialog() } },
      error => this.logger.error('[GO] Error with hub check', error)
    );
  }

  get dialData(): HelpDialData {
    return {
      title: this.langService.instant('NETWORK_ERROR'),
      content: this.langService.instant('NETWORK_ERROR_CONTENT_HUB'),
      ok: 'OK'
    };
  }

  private setSpinnerMode() {
    if (this.hasModuleNav()) {
      if (this.sidenavOpened) {
        this.spinnerMode = this.moduleSidenavOpened ? 'bothOpened' : 'navOpened';
      } else {
        this.spinnerMode = this.moduleSidenavOpened ? 'moduleSidenavOpened' : 'neitherOpened';
      }
    } else {
      this.spinnerMode = this.sidenavOpened ? 'navOpened' : 'neitherOpened';
    }
  }

  private hasModuleNav() {
    const currentURL = window.location.href;
    if ((currentURL.indexOf('main/home') > -1) || (currentURL.indexOf('main/promo') > -1)) { return true; }
    return false;
  }

  private toggleVendorSwitchingLoader(enabled: boolean) {
    this.pbOverlayEnabled = enabled;

    if (enabled) {
      this.loadingSpinnerService.addLoadingItemId(this.vendorSwitchingLoadingItemId);
    } else {
      this.loadingSpinnerService.removeLoadingItemId(this.vendorSwitchingLoadingItemId);
    }
  }

  private loadSupport() {
    const webConfig = this.appConfig.get().webConfig;
    const trainingOption = this.appConfig.get().webConfig.trainingOption;
    let showTraining = true;

    if (!webConfig.supportEnabled) {
      this.globalService.emitTrainingLink(webConfig.supportEnabled);
      showTraining = false;
    } else if (trainingOption === this.constants.TRAINING_OPTIONS.ACCORD) {
      this.documentService.getTrainingAccountExist(localStorage.getItem('username'))
        .subscribe(
          response => {
            showTraining = response;
            this.globalService.emitTrainingLink(showTraining);
          },
          error => {
            this.logger.error('[GO] Error verifying user', error);
            showTraining = false;
          });
    }
  }

  private loadApps() {
    const products = this.productService.getProducts();
    this.globalService.emitApps(products);
    this.goInitService.setClient(getClientCode());
    this.goInitService.setProducts(products);
  }

  private onHubError() {
    this.globalService.emitHubCheck(true);
  }

  private subscribeGoNavigation() {
    this.navigationSubscription = this.goNavigationService.navigationChange.subscribe((selection) => {
      if (!selection) {
        return;
      }

      if (selection.external) {
        this.logger.debug('[GO] Navigate to external app', selection);
        window.open(selection.url);
        return;
      }

      switch (selection.code) {
        case AppCode.Assort:
        case AppCode.DP:
        case AppCode.Insights:
        case AppCode.PriceNg:
        case AppCode.Promo:
        case AppCode.RetailDashboard:
        case AppCode.SC: {
          this.globalService.toggleModuleLoading(true);
          this.router.navigateByUrl(selection.url);
          break;
        }
        case AppCode.Hub: {
          this.globalService.toggleModuleLoading(true);
          this.openHub(selection.url);
          break;
        }
        case AppCode.Reports: {
          this.router.navigateByUrl(selection.url);
          break;
        }

        case AppCode.Home:
          this.router.navigate(['main/my-dashboard']);
          break;

        case AppCode.Preferences:
          this.router.navigate(['main/preferences']);
          break;
        case AppCode.SwitchAccount:
          const userGroup = localStorage.getItem('userGroup');
          this.userGroup = userGroup.split(',')[1]
          this.openVendorSwitchingDialog();
          break;
        case AppCode.SignOut:
          this.router.navigate(['/logout']);
          break;

        case AppCode.Contact: {
          this.checkSupportMail();
          break;
        }
        case AppCode.Training: {
          this.openTraining();
          break;
        }

        default: {
          this.logger.debug('[GO] Unhandled navigation selection:', selection);
          break;
        }
      }
    })
  }

  private openHub(hubUrl: string) {
    this.serverService.getCookieFromQlik(this.appConfig.get().webConfig.hubJwtUrl, {}).subscribe(
      res => {
        this.globalService.toggleModuleLoading(false);
        window.open(hubUrl + 'hub/');
      },
      error => {
        this.logger.error('[GO] Error retrieving Qlik cookie', error);
        this.globalService.toggleModuleLoading(false);
        this.onHubError();
      },
      () => this.loadingSpinnerService.removeLoadingItemId('MSG.LOADING')
    );
  }

  private openTraining() {
    if (this.appConfig.get().webConfig.trainingOption === TRAINING_OPTIONS.PDF) {
      this.router.navigate(['main/document']);
    } else {
      this.openTrainingUrl();
    }
  }

  private openTrainingUrl() {
    if (this.environment.runLocal) { return; }
    const username = localStorage.getItem('username');
    this.documentService.getTrainingSSOToken().subscribe(res => {
      window.open(this.appConfig.get().webConfig.trainingUrl + '/SSO.aspx?ssouser=' + username + '&ssotoken=' + res.token, '_blank');
    }, err => {
      this.logger.error('[GO] Error getting training SSO token');
    });
  }

  private checkSupportMail() {
    if (this.appConfig.get().webConfig.supportEmailException) {
      this.showSupportEmailMessage(this.appConfig.get().webConfig.supportEmailException);
    } else {
      window.open('mailto:' + this.initConfig.get().supportEmail, '_self');
    }
  }

  private showSupportEmailMessage(message) {
    const dialogRef = this.dialog.open(DialogSupportContact, {
      data: { supportContactMessage: message }
    });
    dialogRef.afterClosed().subscribe(() => { });
  }
}
