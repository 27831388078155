import { Injectable } from '@angular/core';
import { AppCode } from '../models/appOption/AppCode';
import { AppConfigService } from './app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../utilities/utils';
import { GlobalService } from './global.service';
import { ServerService } from './server.service';
import { NGXLogger } from 'ngx-logger';
import { UserService } from './user.service';
import { GoNavigationItem } from 'precima-bootstrap/src/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private appConfig: AppConfigService,
              private globalService: GlobalService,
              private utils: UtilsService,
              private serverService: ServerService,
              private userService: UserService,
              private translateService: TranslateService,
              private logger: NGXLogger) {
  }

  public getProducts(): GoNavigationItem[] {
    const goNavigationItems: GoNavigationItem[] = [];
    const webConfig = this.appConfig.get().webConfig;
    const products = this.getAllProducts();

    for (const app of this.userService.userApps) {
      const product = products.find(x => x.goNavigationItem.code === app);

      if (product) {
        goNavigationItems.push(product.goNavigationItem);

        if (product.initFunction) {
          product.initFunction();
        }
      }
    }

    goNavigationItems.push({
      code: AppCode.NielsenConnect,
      label: this.translateService.instant('NIELSEN_CONNECT'),
      external: true,
      url: webConfig.niqConnectLink,
    });

    return goNavigationItems;
  }

  private getAllProducts() {
    const webConfig = this.appConfig.get().webConfig;

    return [
      {
        goNavigationItem: {
          code: AppCode.AA,
          label: this.translateService.instant('ADVANCED_ANALYTICS'),
          external: true,
          path: 'advanced-analytics',
          url: webConfig.aaDomain + '/dashboard/advanced-analytics?id_token=' + localStorage.getItem('id_token'),
        }
      },
      {
        goNavigationItem: {
          code: AppCode.ActivateInsights,
          label: this.appConfig.get().groupAccessConfig.precimaApps.indexOf(AppCode.Insights) >= 0 ? this.translateService.instant('ACTIVATE_INSIGHTS')
            : this.translateService.instant('INSIGHTS_PORTAL'),
          external: true,
          url: webConfig.activateInsightsUrl,
        }
      },
      {
        goNavigationItem: {
          code: AppCode.Assort,
          label: this.translateService.instant('ASSORTMENT'),
          external: true,
          path: 'assortment',
          url: 'main/assortment/home',
        }
      },
      {
        goNavigationItem: {
          code: AppCode.CI,
          label: this.translateService.instant('CUSTOMER_INSIGHTS'),
          external: true,
          url: webConfig.ciaUrl,
        }
      },
      {
        goNavigationItem: {
          code: AppCode.CPL,
          label: this.translateService.instant('CUSTOM_PRODUCT_LISTS'),
          external: true,
          path: 'custom-product-list',
          url: webConfig.aaDomain + '/dashboard/custom-product-list?id_token=' + localStorage.getItem('id_token'),
        }
      },
      {
        goNavigationItem: {
          code: AppCode.DP,
          label: this.translateService.instant('DATA_PORTAL'),
          external: true,
          path: 'data-portal',
          url: 'main/data-portal/reports',
        }
      },
      {
        goNavigationItem: {
          code: AppCode.Hub,
          label: this.translateService.instant('HUB'),
          external: true,
          url: this.appConfig.get().webConfig.hubJwtUrl,
        },
        initFunction: () => this.initHub()
      },
      {
        goNavigationItem: {
          code: AppCode.Insights,
          label: this.translateService.instant('INSIGHTS_PORTAL'),
          external: true,
          path: 'insights-portal',
          url: 'main/insights-portal/categories',
        }
      },
      {
        goNavigationItem: {
          code: AppCode.Offer,
          label: this.translateService.instant('OFFER_ADS'),
          external: true,
          url: this.appConfig.get().webConfig.offerAdMgmtUrl,
        }
      },
      {
        goNavigationItem: {
          code: AppCode.Price,
          label: this.translateService.instant('PRICE'),
          external: true,
          path: 'price',
          url: webConfig.pricingDomain + '/home?id_token=' + localStorage.getItem('id_token'),
        }
      },
      {
        goNavigationItem: {
          code: AppCode.PriceNg,
          label: this.translateService.instant('PRICE'),
          external: true,
          path: 'price',
          url: '/main/price/home',
        }
      },
      {
        goNavigationItem: {
          code: AppCode.Promo,
          label: this.translateService.instant('PROMO'),
          external: true,
          path: 'promo',
          url: 'main/promo/home',
        }
      },
      {
        goNavigationItem: {
          code: AppCode.Reports,
          label: this.translateService.instant('REPORTS'),
          external: false,
          path: 'home',
          url: '/main/home',
        },
        initFunction: () => this.initReports()
      },
      {
        goNavigationItem: {
          code: AppCode.RetailDashboard,
          label: this.translateService.instant('RETAIL_DASHBOARD'),
          external: true,
          path: 'retail-dashboard',
          url: 'main/retail-dashboard',
        }
      },
      {
        goNavigationItem: {
          code: AppCode.SC,
          label: this.translateService.instant('SUPPLY_CHAIN'),
          external: true,
          path: 'supply-chain',
          url: 'main/supply-chain/home',
        }
      },
      {
        goNavigationItem: {
          code: AppCode.Segmentation,
          label: this.translateService.instant('SEGMENTS_AUDIENCES'),
          external: true,
          url: webConfig.segmentUrl,
        }
      }
    ];
  }

  private initHub() {
    this.serverService.getCookieFromQlik(this.appConfig.get().webConfig.hubJwtUrl, {}).subscribe(
      () => {
        this.logger.debug('[GO] Successfully retrieved Qlik cookie');
      },
      error => {
        this.logger.error('[GO] Error retrieving Qlik cookie', error);
        this.onHubError();
      });
  }

  private onHubError() {
    this.globalService.emitHubCheck(true);
  }

  private initReports() {
    this.globalService.emitReportsConfig();
  }
}
