import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from '../services/document.service';
import { AppConfigService } from '../services/app-config.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-planogram-container',
  templateUrl: './planogram-container.component.html'
})
export class PlanogramContainerComponent implements OnInit {

  paramSub: any;
  fileKey: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private documentService: DocumentService,
              private appConfig: AppConfigService,
              private logger: NGXLogger) {
  }

  ngOnInit() {
    this.appConfig.init((config) => {
      this.paramSub = this.route.params.subscribe(params => {
        this.fileKey = params['file-key'];

        if (!this.fileKey) {
          this.router.navigate(['main']);
        } else {
          this.openDocument(this.fileKey);
        }
      });
    }, (error) => {
      this.logger.error('[GO] Error initializing config', error);
    });
  }

  openDocument(fileKey) {
    this.documentService.openDocumentWithKey(this.appConfig.get().webConfig.awsPlanogramBucketConfig, fileKey, false);
  }
}
