import { Component, Inject, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AuthenticateService } from '../../../services/authenticate.service';
import { QlikSessionService } from '../../../services/qlik-session.service';
import { UtilsService } from '../../../utilities/utils';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BroadcastService } from '../../../services/broadcast.service';
import { BroadcastMessageType } from '../../../models/broadcast-message-type';

@Component({
  selector: 'app-user-vendor-option-dialog',
  templateUrl: './vendor-group-switching-dialog.component.html',
  styleUrls: ['./vendor-group-switching-dialog.component.less']
})
export class UserVendorOptionDialogComponent implements OnInit {

  public userGroup: String;
  public password: String;
  public showPasswordInput: boolean;
  public ssoEnabled: boolean;
  isLoading = false;
  loginErrorMsg: string = null;

  constructor(
    private utils: UtilsService,
    private qlikSessionService: QlikSessionService,
    public dialogRef: MatDialogRef<any>,
    private authenticateService: AuthenticateService,
    private broadcastService: BroadcastService,
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public data: { userGroups: String[], userGroup: String, password: String, showPasswordInput: boolean, ssoEnabled: boolean }) {
    this.userGroup = this.data.userGroup || this.data.userGroups[0];
    this.password = this.data.password;
    this.showPasswordInput = this.data.showPasswordInput;
    this.ssoEnabled = this.data.ssoEnabled;


  }

  ngOnInit() {
    if (this.showPasswordInput) {
      this.password = '';
    }
  }

  public switchGroup() {
    localStorage.setItem('waitSwitch', 'true');
    this.qlikSessionService.deleteQlikProxySessions().then(res => {
      this.selectGroup();
    });
  }

  private selectGroup() {
    this.qlikSessionService.vendorSwitchOn(true);
    const data = {
      userGroup: this.userGroup,
      password: this.password,
      showPasswordInput: this.showPasswordInput,
      ssoEnabled: this.ssoEnabled
    };

    this.isLoading = true;
    const username = localStorage.getItem('username');

    if (this.ssoEnabled) {
      return this.authenticateService.silentLogin(username,  data.userGroup, () => {
        localStorage.removeItem('waitSwitch');
        this.utils.contextRootRedirect();
        this.broadcastService.postMessage({
          type: BroadcastMessageType.vendorSwitchSuccess,
          message: 'Switched to vendor group (SSO): ' + data.userGroup
        });
        this.isLoading = false;
      }, (error) => {
        this.loginErrorMsg = error.description;
        this.isLoading = false;
      });
    } else {
      if (!this.password && this.showPasswordInput) {
          this.loginErrorMsg = 'Please enter a password';
      }
      return this.authenticateService.login(username, this.password.toString(), data.userGroup, () => {
        localStorage.removeItem('waitSwitch');
        this.utils.contextRootRedirect();
        this.broadcastService.postMessage({
          type: BroadcastMessageType.vendorSwitchSuccess,
          message: 'Switched to vendor group (non-SSO): ' + data.userGroup
        });
        this.isLoading = false;
      }, (error) => {
        this.loginErrorMsg = error.description;
        this.isLoading = false;
      });
    }
  }

  public closeDialog() {
    this.qlikSessionService.vendorSwitchOn(false);
    this.dialogRef.close();
  }

  public cancelEnabled() {
    return localStorage.getItem('userGroup') !== null;
  }
}
