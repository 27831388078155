import { Inject, Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import * as constants from '../constants';
import { AppConfigService } from '../services/app-config.service';
import { InitConfigService } from '../services/init-config.service';
import { AppCode } from '../models/appOption/AppCode';
import { IConfiguration } from '../models/iconfiguration';
import { NGXLogger } from 'ngx-logger';
import jwtDecode from 'jwt-decode';

@Injectable()
export class UtilsService {

  userPermissions = [] as string[];
  appPermissions = [] as string[];

  constructor(@Inject('Environment') private environment: any,
    private appConfig: AppConfigService,
    private initConfig: InitConfigService,
    private logger: NGXLogger) {
  }

  public getWebAuthInstance(group) {
    if (group) {
      return new auth0.WebAuth({
        domain: this.initConfig.get().auth0Domain,
        clientID: this.initConfig.get().auth0ClientId,
        responseType: 'token',
        scope: 'openid profile email vs offline_access ' + encodeURIComponent(group)
      });
    } else {
      return new auth0.WebAuth({
        domain: this.initConfig.get().auth0Domain,
        clientID: this.initConfig.get().auth0ClientId,
        responseType: 'token',
        scope: 'openid'
      });
    }
  }

  public getWebAuthInstanceForSsoClient(ssoClient) {
    const domain = this.appConfig.get().webConfig.auth0Domain;
    const ssoClientConfig = this.appConfig.get().webConfig.ssoClientConfigMap[ssoClient];
    return new auth0.WebAuth({
      domain: domain,
      clientID: ssoClientConfig.clientId,
      responseType: 'token',
      scope: 'openid'
    });
  }

  public getRefreshTokenOptionInstance(group: string, refreshToken: string) {
    return {
        realm: this.initConfig.get().adConnection,
        clientID: this.initConfig.get().auth0ClientId,
        scope: 'openid profile email vs offline_access ' + encodeURIComponent(group),
        grantType: 'refresh_token',
        refreshToken: refreshToken
      }
  }

  public getTokenClaimValue(claim) {
    const token = jwtDecode(localStorage.getItem('id_token'));
    return token[claim];
  }

  public checkHelpDocumentsAccess() {
    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (groups[i].startsWith(this.appConfig.get().groupAccessConfig.groupAccessConst.PRECIMA_USERS_PREFIX) || groups[i].startsWith(this.appConfig.get().webConfig.clientUserPrefix)) {
        return true;
      }
    }

    return false;
  }

  public getSsoClients() {
    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);
    const ssoClientsList: string[] = [];

    for (let i = 0; i < groups.length; i++) {
      for (let j = 0; j < this.appConfig.get().groupAccessConfig.precimaSsoGroupPrefixes.length; j++) {
        if (groups[i].startsWith(this.appConfig.get().groupAccessConfig.precimaSsoGroupPrefixes[j])) {
          ssoClientsList.push(this.appConfig.get().groupAccessConfig.precimaSsoGroupPrefixes[j].split('_')[0]);
        }
      }
    }

    return Array.from(new Set(ssoClientsList));
  }


  public checkPriceAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.Price) < 0) return false;

    return this.checkAccess('price');
  }

  public checkPriceNgAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.PriceNg) < 0) return false;

    return this.checkAccess('pricing');
  }

  public checkAAAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.AA) < 0) return false;

    return this.checkAccess('aa');
  }

  public checkCustomProductListAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.CPL) < 0) return false;

    return this.checkAccess('cpl');
  }

  public checkPromoAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.Promo) < 0) return false;

    return this.checkAccess('promo');
  }

  public checkInsightsPortalAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.Insights) < 0) return false;

    return this.checkAccess('insights');
  }

  public checkActivateInsightsPortalAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.ActivateInsights) < 0) return false;

    return this.checkUserPermissionsStrict('insight2');
  }

  public checkSupplyChainAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.SC) < 0) return false;

    return this.checkUserPermissionsStrict('sc');
  }

  public checkRetailDashboardAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.RetailDashboard) < 0) return false;

    return this.checkUserPermissionsStrict('retail-dashboard');
  }

  public checkAdminAccess() {
    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);

    for (let i = 0; i < groups.length; i++) {
      if (this.appConfig.get().groupAccessConfig.precimaAdminGroups.indexOf(groups[i]) >= 0) {
        return true;
      }
    }

    return false;
  }

  public checkAssortmentAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.Assort) < 0) return false;

    return this.checkAccess('assortment');
  }

  public checkReportAccess() {
    return this.getPrecimaApps().indexOf(AppCode.Reports) >= 0;
  }

  public checkSegmentationAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.Segmentation) < 0) return false;

    return this.checkUserPermissionsStrict('segmt');
  }

  public checkOfferAdsAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.Offer) < 0) return false;

    return this.checkUserPermissionsStrict('ofmgt');

  }

  public checkDataPortalAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.DP) < 0) return false;

    return this.checkAccess('data-portal') || this.checkAccess('data-portal-category') || this.checkAccess('data-portal-total');
  }

  public checkSupportAccess() {
    return this.initConfig.get().supportEmail;
  }

  public checkCIAAccess() {
    return this.appConfig.get().webConfig.ciaUrl;
  }

  public checkHubAccess() {
    if (this.getPrecimaApps().indexOf(AppCode.Hub) < 0) return false;

    // Check hub access by permission as below:
    return this.checkAccess('hub');
  }

  public getUserFullName() {
    return this.getTokenClaimValue(constants.PRECIMA_GO_USER_NAME_CLAIM);
  }

  public getUserEmail() {
    return this.getTokenClaimValue(constants.PRECIMA_GO_USER_EMAIL_CLAIM);
  }

  public getClientUserPrefix() {
    return this.appConfig.get().webConfig.clientUserPrefix;
  }

  public getPrecimaUsersPrefix() {
    if (this.appConfig.get().groupAccessConfig.groupAccessConst.PRECIMA_USERS_PREFIX) {
      return this.appConfig.get().groupAccessConfig.groupAccessConst.PRECIMA_USERS_PREFIX;
    }
    return null;
  }

  public isVendorSwitchingAllowed() {
    if (this.appConfig.get().webConfig.vendorSwitching) {
      this.logger.debug('[GO] Vendor switching enabled');
      return true;
    }

    this.logger.debug('[GO] Vendor switching disabled');
    return false;
  }

  public getAssociatedSsoClient() {
    if (this.appConfig.get().webConfig.clientUserPrefix === this.appConfig.get().groupAccessConfig.groupAccessConst.LCL_USERS_PREFIX) {
      return this.appConfig.get().groupAccessConfig.groupAccessConst.SDM_USERS_PREFIX.split('_')[0];
    } else if (this.appConfig.get().webConfig.clientUserPrefix === this.appConfig.get().groupAccessConfig.groupAccessConst.SDM_USERS_PREFIX) {
      return this.appConfig.get().groupAccessConfig.groupAccessConst.LCL_USERS_PREFIX.split('_')[0];
    }
  }

  public getSsoClientConfig(clientName) {
    return this.appConfig.get().webConfig.ssoClientConfigMap[clientName];
  }

  public isSsoAllowed() {
    const ssoClientsList = this.getSsoClients();
    return (ssoClientsList && ssoClientsList.length > 1);
  }

  public getChosenGroup() {
    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);
    const clientUsersPrefix = this.getClientUserPrefix();
    const precimaUsersPrefix = this.getPrecimaUsersPrefix();
    if ((groups.length === 1) && (groups[0].includes(clientUsersPrefix) || groups[0].includes(precimaUsersPrefix))) {
      return groups[0];
    }
    return '';
  }

  public getVendorAccess() {
    if (this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_AUTHORIZATION)) {
      const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_AUTHORIZATION)['groups'];
      const clientUsersPrefix = this.getClientUserPrefix();
      const accessInfo = [];
      const precimaUsersPrefix = this.getPrecimaUsersPrefix();
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].includes(clientUsersPrefix) || (groups[i].includes(precimaUsersPrefix))) {
          accessInfo.push(groups[i]);
        }
      }

      return accessInfo.toString();
    }

    return [].toString();
  }

  public getUserDataAccess() {
    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);
    const accessInfo = [];
    const dataAccessPrefixes = this.appConfig.get().groupAccessConfig.precimaUserDataAccessPrefixes;
    for (let i = 0; i < dataAccessPrefixes.length; i++) {
      for (let j = 0; j < groups.length; j++) {
        if (groups[j].startsWith(dataAccessPrefixes[i])) {
          const index = dataAccessPrefixes[i].indexOf('_');
          accessInfo.push(dataAccessPrefixes[i].substring(0, index));
          break;
        }
      }
    }

    return accessInfo.toString();
  }

  public getUserRole() {
    const groups = this.getTokenClaimValue(constants.PRECIMA_GO_GROUP_CLAIM);
    const userRole = this.appConfig.get().groupAccessConfig.groupUserRole;
    const roleInfo = [];

    for (let i = 0; i < groups.length; i++) {
      for (let j = 0; j < userRole.length; j++) {
        if (groups[i].toUpperCase().startsWith(userRole[j].groupPrefix)) {
          const roleValue = userRole[j].userRole;
          if (roleInfo.indexOf(roleValue) === -1) {
            roleInfo.push(roleValue);
          }
          break;
        }
      }
    }

    return roleInfo.toString();
  }

  public getUserNameFromToken() {
    return this.getTokenClaimValue(constants.PRECIMA_GO_USER_NAME_NAMESPACE);
  }

  /**
   * Retrieves and returns an array of enabled apps for client.
   * Enabled apps are specified in config file for each client as an array of strings.
   */
  getPrecimaApps(): String [] {
    return this.appConfig.get().groupAccessConfig.precimaApps;
  }

  private checkAccess(app: string): boolean {
    const appPermission = this.getAppPermission(app);
    if (appPermission) {
      if (((!this.userPermissions) || (this.userPermissions.length == 0)) && this.appConfig.get()) {
        this.getUserPermissions();
      }
      return this.userPermissions.indexOf(appPermission) > -1;
    }

    this.logger.warn('[GO] Cannot find app permission:', this.userPermissions, app);

    return false;
  }

  private getAppPermission(app: string): string {
    if (((!this.appPermissions) || (this.appPermissions.length == 0)) && this.appConfig.get()) {
      this.appPermissions = this.appConfig.get().webConfig['appPermissions'];
    }
    return this.appPermissions.find(el => el.indexOf(':' + app) > -1);
  }

  private checkUserPermissionsStrict(userPermission: string): boolean {
    this.getUserPermissions();
    return !!this.userPermissions.find(el => el.substring(el.indexOf(':') + 1) == userPermission);
  }

  public prepareAccess(config: IConfiguration) {
    this.getAppPermissions(config);
    this.getUserPermissions();
  }

  private getAppPermissions(config: IConfiguration) {
    this.appPermissions = config.webConfig['appPermissions'];
  }

  private getUserPermissions() {
    const token = localStorage.getItem('id_token');
    if (token) {
      const KEY_AUTH_DOMAIN = 'https://auth.precima.io/user_authorization';
      const KEY_PERMISSIONS = 'permissions';
      // TODO update this when all permissions have been migrated to the new namespace
      this.userPermissions  = jwtDecode(token)[KEY_AUTH_DOMAIN + '/' + KEY_PERMISSIONS] ||
        jwtDecode(token)[KEY_AUTH_DOMAIN][KEY_PERMISSIONS];
    }
  }

  public contextRootRedirect() {
    const href = location.href;
    this.logger.debug('[GO] Redirect to context root:', href);

    if (href.indexOf('/login') >= 0) {
      location.reload();
    } else {
      if (href.indexOf('/insights-portal') >= 0) {
        location.href = '/main/insights-portal/categories';
      } else if (href.indexOf('/supply-chain') >= 0) {
        location.href = '/main/supply-chain/home';
      } else if (href.indexOf('/data-portal') >= 0) {
        location.href = '/main/data-portal/reports';
      } else if (href.indexOf('/retail-dashboard') >= 0) {
        location.href = '/main/retail-dashboard';
      } else {
        location.href = '/main/dashboard';
      }
    }
  }
}
