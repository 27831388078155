<div *ngIf="showLabel" class="menu" [ngClass]="mode" [style.height.px]="menuHeight" [style.maxHeight.px]="menuHeightMax"
     (mouseleave)="mouseLeave($event)">
  <a href="{{option.url}}" class="item" [ngClass]="{'selected' : option.selected}"
    *ngFor="let option of apps; let i = index;"
    (click)="select({index:i, app:option})" (mouseover)="changeColor({hover:true, option:option})" (mouseleave)="changeColor({hover:false, option:option})">
    <img src="{{option.iconPath}}" class="app-icon">
      <span class="app-name">{{option.title | translate }}
      </span>
  </a>

  <div>
    <app-support *ngIf="hasTraining" [mode]="mode" [opened]="opened" [hasTraining]="hasTraining" (onSelect)="select($event)"
      (onSupportMenuClose)="onSupportMenuClose.emit($event)"></app-support>
  </div>
</div>

<div *ngIf="!showLabel" class="menu-collapsed" [ngClass]="mode" [style.height.px]="menuHeight" [style.maxHeight.px]="menuHeightMax"
     (mouseleave)="mouseLeave($event)">
  <a href="{{option.url}}" class="item" [ngClass]="{'selected' : option.selected}"
    *ngFor="let option of apps; let i = index;"
    (click)="select({index:i, app:option})" (mouseover)="changeColor({hover:true, option:option})" (mouseleave)="changeColor({hover:false, option:option})">
    <pb-tooltip tooltip="{{option.title | translate }}">
      <img src="{{option.iconPath}}" class="app-icon">
    </pb-tooltip>
  </a>

  <div>
    <pb-tooltip tooltip="support">
    <app-support [mode]="mode" [opened]="opened" [hasTraining]="hasTraining" (onSelect)="select($event)"
      (onSupportMenuClose)="onSupportMenuClose.emit($event)"></app-support>
    </pb-tooltip>
  </div>
</div>
