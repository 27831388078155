import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { AuthenticateService } from './authenticate.service';
import { QlikSessionService } from './qlik-session.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private logger: NGXLogger,
    private qlikSessionService: QlikSessionService,
    private authenticateService: AuthenticateService) {
  }

  public canActivate() {
    const isLoggedIn = this.authenticateService.isAuthenticated();
    const isVendorSwitching = this.qlikSessionService.getIsVendorSwitchOn();

    if (!isLoggedIn) {
      if (!localStorage.getItem('id_token')) {
        this.logger.debug('[GO] User is not authenticated. Redirecting to logout.');
        this.router.navigate(['/logout']);
      } else if (isVendorSwitching) {
        this.logger.debug('[GO] Waiting for vendor group change');
      } else {
        this.authenticateService.logout('/logout/session-timeout');
      }
    }

    return isLoggedIn;
  }
}
