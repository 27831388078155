import { Component, HostBinding, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { filter } from 'rxjs/operators';
import { AuthGuardService } from './services/auth-guard.service';
import { GlobalService } from './services/global.service';
import { InitConfigService } from './services/init-config.service';
import { LangService } from './services/lang.service';
import { CookieService } from 'ngx-cookie-service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { PbSpinnerService } from 'precima-bootstrap/src/components';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  @HostBinding('class')
  moduleCssClass: string;
  hideQARibbon = true;

  constructor(private authGuardService: AuthGuardService,
    private translate: TranslateService,
    private router: Router,
    public globalService: GlobalService,
    private loadingSpinnerService: PbSpinnerService,
    private initConfigService: InitConfigService,
    private langService: LangService,
    private logger: NGXLogger,
    private cookieService: CookieService,
    private titleService: Title,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: HTMLDocument) {

    this.translate.setDefaultLang('en');

    /** Get language setting from server side config: */
    if (!this.initConfigService.get()) {
      this.initConfigService.load().then(
        (initConfig) => {
          this.applyLang(this.initConfigService.get());
        },
        (error) => {
          this.logger.error('[GO] Error initializing config', error);
        });
    } else {
      this.applyLang(this.initConfigService.get());
    }
  }
  applyLang(initConfig) {
    const langs = initConfig.langs;
    this.logger.debug('[GO] Available languages', langs);
    if (langs && langs.length > 0) {
      this.langService.use(langs[0]);
      if (langs.length > 1) {
        this.globalService.emitLangs(langs);
      }
    }
  }

  ngOnInit() {
    this.addChurnZeroScript();
    this.checkQACookie();

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const applicationPath = event.url.match(/insights-portal|supply-chain|promo|data-portal|assortment|price|my-dashboard|retail-dashboard|retail-dashboard-demo|retail-dashboard-poc|supply-chain-demo/);
        let titleLanguageKey = 'NIQ_RETAIL_SUITE';

        if (applicationPath && applicationPath.length > 0) {
          this.moduleCssClass = applicationPath[0];
          this.globalService.toggleModuleLoading(false);
          this.loadingSpinnerService.removeLoadingItemId('MSG.LOADING');
          this.loadingSpinnerService.setLoadingMessage('Running report crunching numbers, please wait...');
          titleLanguageKey = applicationPath[0].replace(/-/g, '_').toUpperCase();
        } else {
          this.moduleCssClass = 'precima-go-body';
        }

        this.translate.get(titleLanguageKey).subscribe(text => {
          this.titleService.setTitle(text);
        });
      });

    setInterval(() => {
      if (window.location.href.indexOf('login') < 0) {
        this.authGuardService.canActivate();
      }
    }, 3000);
  }

  private addChurnZeroScript() {
    if (this.initConfigService.get().churnZeroEnabled) {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `${this.initConfigService.get().churnZeroUrl}/churnzero.js`;
      this.renderer.appendChild(document.head, script);
    }
  }

  checkQACookie() {
    if (this.cookieService.get('origin') === 'qa') {
      this.hideQARibbon = false;
    }
  }
}
