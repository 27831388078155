import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { PrecimaBootstrapModule } from 'precima-bootstrap/src/components/precimaBootstrap.module';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import * as constants from './constants';
import { InitConfigService } from './services/init-config.service';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { GoMaterialModule } from './material/go-material.module';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { goComponents } from './go.components';
import { goServices } from './services/go.services';
import { goPipes } from './pipes/go.pipes';
import { goRoutes } from './go.routes';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { CsAppApiInterceptor } from './interceptors/cs-app-api.interceptor';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function loadConfiguration(initConfig: InitConfigService): Function {
  return () => initConfig.load();
}

declare let heapLoad: Function;

export function loadHeap(initConfig: InitConfigService) {
  if (!initConfig.get()) {
    return () => initConfig.load().then(() => {
      heapLoad(initConfig.get().haEnvironmentId);
      console.log('[GO] Config loaded. Heap initialized: ' + initConfig.get().haEnvironmentId);
    });
  } else {
    return () => {
      heapLoad(initConfig.get().haEnvironmentId);
      console.log('[GO] Config already loaded. Heap initialized: ' + initConfig.get().haEnvironmentId);
    };
  }
}

@NgModule({
  declarations: [
    ...goComponents,
    ...goPipes
  ],
  imports: [
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(goRoutes, { useHash: false, onSameUrlNavigation: 'reload' }),
    GoMaterialModule,
    PrecimaBootstrapModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    BrowserAnimationsModule,
    RecaptchaV3Module,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    ...goServices,
    { provide: 'Environment', useValue: environment },
    { provide: 'Constants', useValue: constants },
    { provide: APP_INITIALIZER, useFactory: loadConfiguration, deps: [InitConfigService], multi: true },
    { provide: APP_INITIALIZER, useFactory: loadHeap, deps: [InitConfigService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CsAppApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    NGXLogger,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
