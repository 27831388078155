import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getApiUrl } from '../utilities/env-utils';
import { NGXLogger } from 'ngx-logger';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-niq-auth',
  templateUrl: './niq-auth.component.html',
  styleUrls: ['./niq-auth.component.less']
})
export class NiqAuthComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private httpClient: HttpClient,
              private logger: NGXLogger) { }

  ngOnInit() {
    if (localStorage.getItem('niq_token')) {
      this.router.navigate(['main']);
    }

    this.route.queryParams.subscribe(params => {
      const code = params['code'];
      this.httpClient.get(getApiUrl() + '/niq-auth/tokens?code=' + code, {}).subscribe(response => {
        this.logger.debug('[GO] Retrieved NIQ tokens', response);
        localStorage.setItem('niq_token', response['access_token']);
        this.router.navigate(['main']);
      }, error => {
        this.logger.error('[GO] Error retrieving NIQ tokens', error);
      });
    })
  }
}
