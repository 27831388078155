<div class="ap-header">

  <pb-header-bar class="row justify-content-between" [headerTitle]='category | translate'>
    <div class="ap-header-search-box">

      <div class="category-btn" [matMenuTriggerFor]="menu">
        <span class="text">{{getCategorySelected()}}</span>
        <span class="arrow"></span>
      </div>

      <mat-menu #menu="matMenu" class="category-menu">
        <div class="item" *ngFor="let option of reportCategoryOptions; let i = index;"
          (click)="onChangeCategory(option)">
          {{option | translate}}
        </div>
      </mat-menu>

      <input style="display: inline;" placeholder="{{'SEARCH_REPORT_PLACE_HOLDER' | translate}}" class="search-box"
        type="text" name="searchText" [(ngModel)]="searchText" (keyup)="filterWhileTyping()" [style.width]="'100%'">

      <i class="linear-icon linear-icon--magnifier"></i>
    </div>
  </pb-header-bar>
</div>

<div class="ap-reports">
  <!-- Reports loading -->
  
  <pb-progress-bar [hideLoading]="reportLoaded" 
    [afterHereMessage]="'MSG.AFTER_HERE' | translate"
    [loadingMessage]="'MSG.LOADING'|translate"
    [patienceMessage]="'MSG.LOADING_APPRECIATE_PATIENCE'|translate"
    [waitMessage]="'MSG.LOADING_PLEASE_WAIT'|translate"
    [takingLongerMessage]="'MSG.LOADING_TAKING_LONGER'|translate"
    [relaunchMessage]="'MSG.RELAUNCH_PAGE'|translate"
    [clickHereMessage]="'MSG.HERE'|translate"
    instantClose="true">
</pb-progress-bar>

  <!-- Reports loaded -->
  <div class="ap-reports-loaded" *ngIf="reportLoaded">

    <!-- header -->
    <div class="ap-reports-head row">

      <!-- reports counts -->
      <div class="ap-reports-head-counts">
        <span class="ap-reports-head-category">{{category | translate}}</span>
        <span class="ap-reports-head-slash">/</span>
        <span class="ap-reports-head-all">{{reports.length}}
          <span translate>REPORT_COUNT</span>
        </span>
      </div>

      <!-- sorting and modes -->
      <div class="row ap-reports-head-options d-flex justify-content-between">
        <!-- sorting -->
        <div class="ap-reports-head-options-sorting">
          <div class="ap-reports-head-options-sorting-by" translate>SORT_BY
          </div>
          <div class="ap-reports-head-options-sorting-dropdown">
            <mat-select [(ngModel)]="sortBySelection" (ngModelChange)="onSort()" class="ap-header-sort-select">
              <mat-option value="{{filter}}" *ngFor="let filter of sortingFilters; let i = index;">
                {{filter | translate}}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <!-- modes -->
        <div class="ap-reports-head-options-modes">
          <i class="linear-icon linear-icon--list tooltip" [ngClass]="{'half-visible':isGrids}"
            (click)="gridsMode(false)"><span class="tooltiptext-modes" translate>LIST_VIEW</span></i>
          <i class="linear-icon linear-icon--border-all tooltip" [ngClass]="{'half-visible':!isGrids}"
            (click)="gridsMode(true)"><span class="tooltiptext-modes" translate>TILES_VIEW</span></i>
        </div>
      </div>
    </div>

    <div *ngIf="reports.length == 0" class="no-reports" translate><i
        class="linear-icon linear-icon--notification-circle"></i>NO_REPORT_FOUND_ALERT</div>

    <!-- Grid mode -->
    <div *ngIf="isGrids && !(reports.length == 0)" class="ap-reports-grid">
      <div class="row">
        <div class="tile" *ngFor="let report of reports; let i = index;">
          <app-report [ngClass]="{'mode-list':!isGrids, 'mode-grids':isGrids}" [report]="report"
            [thumbnailsEnabled]="thumbnailEnabled" [isGrids]="isGrids" (favorite)="onFavorite($event)">
          </app-report>
        </div>
      </div>
    </div>

    <!-- List mode -->
    <!-- <div *ngIf="!isGrids" class="ap-reports-list-container"> -->
    <div *ngIf="!isGrids && !(reports.length == 0)" class="ap-reports-list">
      <table class="ap-reports-list-tb">
        <tr class="ap-reports-list-tb-head">
          <th class='ap-reports-list-tb-head-name' scope="col" translate>NAME_COLUMN_TITLE</th>
          <th class="ap-reports-list-tb-head-category" scope="col" translate>CATEGORY_COLUMN_TITLE</th>
        </tr>
      </table>
      <div class="ap-reports-list-body">
        <app-report class="app-report-{{viewMode}}" [report]="report" [gridViewEnabled]="gridMode"
          [thumbnailsEnabled]="thumbnailEnabled" (favorite)="onFavorite($event)"
          *ngFor="let report of reports; let i = index;"></app-report>
      </div>
    </div>
    <!-- </div> -->



  </div>
</div>