import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-user-management-container',
  templateUrl: './user-management-container.component.html',
  styleUrls: ['./user-management-container.component.less']
})
export class UserManagementContainerComponent implements OnInit {
  username: String;
  errorMsg: String;
  successMsg: String;
  fieldsDisabled: boolean;

  constructor(private userService: UserService,
              private logger: NGXLogger) { }

  ngOnInit() {
    this.fieldsDisabled = false;
    this.errorMsg = '';
    this.successMsg = '';
    this.username = '';
  }

  blockUser(block) {
    this.fieldsDisabled = true;
    this.errorMsg = '';
    this.successMsg = '';
    this.userService.validateUser('sAMAccountName', this.username).subscribe(res => {
      if (res) {
        this.userService.updateUserBlock(this.username, block).subscribe(res => {
          this.successMsg = block ? 'USER_BLOCKED' : 'USER_UNBLOCKED';
          this.fieldsDisabled = false;
        }, error => {
          this.logger.error('[GO] Error blocking user', error);

          if (error.status === 401) {
            this.errorMsg = 'You are not authorized to perform this action.';
          } else {
            this.errorMsg = 'ERROR_BLCOKING_UNBLOCKING_USER';
          }

          this.fieldsDisabled = false;
        });
      } else {
        this.errorMsg = 'USER_NOT_EXIST';
        this.fieldsDisabled = false;
      }
    }, (error) => {
      this.logger.error('[GO] Error validating user', error);
      this.errorMsg = error.description;
      this.fieldsDisabled = false;
    });
  }
}
