<div class="support-wrapper">
  <div [matMenuTriggerFor]="appMenu" [ngClass]="mode" class="support"
    (click)="$event.stopPropagation();">
    <img src="../assets/images/graph-thumbnails/svgs/support-thumbnail-white.svg" class="app-icon">
    <span translate class="app-name">{{'support'|translate}}</span>
  </div>

  <div>
    <mat-menu #appMenu="matMenu" class="support-menu" [overlapTrigger]="false">
      <div (mouseleave)="mouseLeave($event)">
        <div class="item" *ngFor="let item of supportItems; let index = index;"
          (click)="select({index: index, app:item})">
            <span translate class="app-name">{{ item.title }}</span>
        </div>
      </div>
    </mat-menu>
  </div>
</div>
