import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NielsenService } from './nielsen.service';
import { InitConfigService } from './init-config.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class NielsenGuardService implements CanActivate {

  constructor(private initConfig: InitConfigService,
              private appConfig: AppConfigService,
              private nielsenService: NielsenService,
              private logger: NGXLogger) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const helper = new JwtHelperService();
    const validTokens = !helper.isTokenExpired(localStorage.getItem('niq_id_token')) &&
      !helper.isTokenExpired(localStorage.getItem('niq_access_token'));

    if (validTokens || !this.initConfig.get().niqIntegrationV5) {
      return true;
    }

    return this.nielsenService.getTokens().toPromise().then(response => {
      this.logger.debug('[GO] Generated new NIQ tokens');
      localStorage.setItem('niq_id_token', response['id_token']);
      localStorage.setItem('niq_access_token', response['access_token']);

      return response['id_token'] !== null && response['access_token'] !== null;
    });
  }
}
