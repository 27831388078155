import { Component, OnInit, Inject, Output, EventEmitter, ViewChild } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { Router } from '@angular/router';
import { IReport } from '../models/report.type';
import { ApContainerComponent } from '../ap-container/ap-container.component';
import { PrecimaGoIntegration } from 'precima-bootstrap/src/components/precima-go-integration/precima-go-integration';
import { NGXLogger } from 'ngx-logger';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-home-container',
  templateUrl: './home-container.component.html',
  styleUrls: ['./home-container.component.less']
})
export class HomeContainerComponent extends PrecimaGoIntegration implements OnInit {

  reports: IReport[] = [];
  categories: String[] = [];
  selectedCategory: String;
  username: String;

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('apContainer') apContainer: ApContainerComponent;
  @Output() pricingTab: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router,
    @Inject('Constants') private constants: any,
    public globalService: GlobalService,
    private logger: NGXLogger) {

    super();
  }

  ngOnInit() {
    this.globalService.toggleModuleLoading(false);
  }

  populateCategories(event: any) {
    this.categories = event;
  }

  selectCategory(category) {
    this.selectedCategory = category;
  }

  changeCategory(category) {
    this.apContainer.onChangeCategory(category);
  }

  setPrecimaGoMenuOpenedImpl(precimaGoMenuOpened: boolean) {
  }

  setModuleMenuOpenedImpl(moduleMenuOpened: boolean) {
    if (this.moduleMenuOpened) {
      this.sidenav.open();
    } else {
      this.sidenav.close();
    }
  }

  setModuleMenuCloseEventImpl(moduleMenuCloseEvent: EventEmitter<any>) { }

  setLanguageChangeEventImpl(langSelected: string) { }

  closeSidenavHandler() {
    this.sidenav.close();
    if (this.moduleMenuCloseEvent) {
      this.moduleMenuCloseEvent.emit();
    }
  }
}
