import { Component, Inject, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import 'rxjs/add/operator/take';
import { AppCode } from '../../models/appOption/AppCode';
import { AppOption } from '../../models/appOption/AppOption';
import { GlobalService } from '../../services/global.service';
import { take } from 'rxjs/internal/operators/take';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../utilities/utils';
import { DocumentService } from '../../services/document.service';
import { map, switchMap } from 'rxjs/operators';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {

  apps = [] as AppOption[];
  supportOptions = [] as AppOption[];
  hasTraining = false;
  supportContact: Object = {};
  


  constructor(private globalService: GlobalService,
    private translateService: TranslateService,
    private logger: NGXLogger,
    private documentService: DocumentService,
    private appConfig: AppConfigService,
    private utils: UtilsService,
    @Inject('Environment') public environment: any
  ) { }

  ngOnInit() {
    this.fetchApps();
    if (this.environment.client !== 'demo') {
      this.fetchTrainingLink();
      this.addContact();
    }
  }

  private fetchApps() {
    this.globalService.obsApps<Array<AppOption>>()
      .pipe(take(1))
      .subscribe(
        res => this.apps = res as AppOption[],
        error => this.logger.error('[GO] Error fetching apps', error)
      );
  }

  private fetchTrainingLink() {

      this.documentService.getTrainingSSOToken().pipe(switchMap(res1 => this.globalService.obsTraningLink().pipe(take(2), map(res2 => [res1, res2]))))
      .subscribe(([res1, res2]) => {
        this.hasTraining = res2;
        if (this.hasTraining) {
          const username = localStorage.getItem('username');
          const training = {
            code: AppCode.Training,
            label: this.translateService.instant('TRAINING'),
            selected: false,
            url: `${this.appConfig.get().webConfig.trainingUrl}/SSO.aspx?ssouser=${username}&ssotoken=${res1.token}`
          } as AppOption;
          this.supportOptions.push(training);
        }
      },
      error => this.logger.error('[GO] Error fetching training link', error)
     )
  }

  emitApp(index: number, appOption: AppOption) {
    this.globalService.emitApp(index, appOption);
  }

  private addContact() {
    
    this.supportContact = this.utils.checkSupportAccess();
    const contact = {
      code: AppCode.Contact,
      label: this.translateService.instant('CONTACT_US'),
      selected: false,
      url: `mailto:${this.supportContact}`
    } as AppOption;

    this.supportOptions.push(contact);
  }
}
