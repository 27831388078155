<mat-sidenav-container class="home-container">
    <mat-sidenav #sidenav mode="side" class="home-sidenav">
        <div class="home-sidebar-content">
            <div class="home-sidebar-content-title-report" translate>REPORTS</div>
            <div class="home-sidebar-content-title-category" translate>CATEGORY</div>

            <!-- secondary nav -->
            <!-- <mat-nav-list> -->
                <ul>
                    <li *ngFor="let category of categories; let i = index;">
                        <a (click)="changeCategory(category)" [ngClass]="{'home-sidebar-content-category' : true, 'selected' : category === selectedCategory}">{{category | translate}}</a>
                    </li>
                </ul>
            <!-- </mat-nav-list> -->
        </div>
        <div class="home-sidebar-close-container" title="{{ 'CLOSE_SECONDARY_SIDENAV' | translate }}">
            <div class="close-sidenav-icon" (click)="closeSidenavHandler()"></div>
        </div>
    </mat-sidenav>

    <!-- container of tiles/list -->
    <app-ap-container #apContainer (sendCategories)="populateCategories($event)" (selectCategory)="selectCategory($event)"></app-ap-container>
</mat-sidenav-container>