<div class="login-container-wrapper">
  <div class="login-container">
    <div class="niq-logo-container">
      <div class="niq-logo"></div>
    </div>

    <div class="login-description">
      {{ "LOGIN_DESCRIPTION" | translate }}
    </div>

    <div class="login-container-loginContent-wrapper">
      <div class="login-container-loginContent">
        <div class="login-container-loginContent-header">
          <div class="title" *ngIf="showLogin || showForgetPwd">
            <h3>{{ "LOGIN_CONTENT_HEADER" | translate }}</h3>
          </div>

          <div class="login-logo {{environment.client}}-login-logo"></div>

          <div *ngIf="!ssoEnabled && (showLogin || showForgetPwd)" class="login-container-loginContent-header-text">
            <div translate *ngIf="showLogin">SIGN_IN_TO_PRECIMA</div>
            <div translate *ngIf="showForgetPwd">RESET_YOUR_PASSWORD</div>
          </div>
        </div>
        <div *ngIf="sessionTimeout" class="login-container-loginContent-content-timeout">
          <div translate class="login-container-loginContent-content-timeout-text">
            SESSION_TIMEOUT
          </div>
        </div>

        <ng-container *ngIf="ssoEnabled">
          <div class="sso-welcome">
            {{ 'SSO_WELCOME' | translate }}
            <div *ngIf="loginErrorMsg" class="error-message">{{ loginErrorMsg | translate }}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="!ssoEnabled">
          <div class="login-container-loginContent-content-username">
            <span *ngIf="showLogin">{{ "USERNAME" | translate }}*</span>
            <mat-form-field *ngIf="showLogin" class='ap-component-loginInput'>
              <input #usernameInput [focus]="focusSettingEventEmitter" [disabled]="loginDisabled" type="text" matInput data-value="Text" [(ngModel)]="username" name="username" (keyup.enter)="login()" (ngModelChange)="onChange($event)">
              <mat-hint translate *ngIf="!validUsername" class="login-container-loginContent-content-password-errormsg">INVALID_USERNAME</mat-hint>
            </mat-form-field>

            <span *ngIf="showForgetPwd">{{ "EMAIL_ADDRESS" | translate }}*</span>
            <mat-form-field *ngIf="showForgetPwd" color='black' class='ap-component-loginInput'>
              <input #emailInput [focus]="focusSettingEventEmitter" type="text" matInput data-value="Text" [(ngModel)]="email" name="email" (keyup.enter)="executeRecaptcha()">
              <mat-hint *ngIf="errorMsg" class="login-container-loginContent-content-password-errormsg" translate>{{errorMsg}}</mat-hint>
            </mat-form-field>
            <div [hidden]="!reCaptchaValidationErr">
              <span class="recaptcha-valid-err" translate>RECAPTCHA_VALIDATION_ERR</span>
              <a (click)="hideReCaptchaValidErr()" href="/login/password-reset" class="recaptcha-here">here</a>
            </div>
            <mat-card class="no-shadow email-sent-container" *ngIf="emailSent">
              <div class="email-sent">
                <div translate class="email-sent-text">RESET_EMAIL_SENT</div>
                <div class="email-confirmation" translate>
                  PASSWORD_CHANGE_EMAIL_SENT
                  <a href="mailto:{{supportContact}}" class="document-container-contact" translate>SUPPORT</a>
                </div>
                <button mat-raised-button class="signin-button" (click)="gotoLogin()"><span translate>LOGIN</span></button>
              </div>
            </mat-card>
          </div>
          <div class="login-container-loginContent-content-password">
            <span *ngIf="showLogin">Password*</span>
            <mat-form-field *ngIf="showLogin" class='ap-component-loginInput'>
              <input id="passwordInput" [disabled]="loginDisabled" type="password" matInput data-value="Password" [(ngModel)]="password" name="password" (keyup.enter)="login()">
              <mat-hint translate *ngIf="loginErrorMsg" class="login-container-loginContent-content-password-errormsg">{{loginErrorMsg}}</mat-hint>
              <mat-hint translate *ngIf="loginConnectionError" class="login-container-loginContent-content-password-errormsg">UNKNOWN_ERROR</mat-hint>
              <!--<mat-hint translate *ngIf="duplicateLogin" class="login-container-loginContent-content-password-errormsg">DUPLICATE_LOGIN</mat-hint>-->

            </mat-form-field>
          </div>
          <div class="login-container-loginContent-content-signin">
            <div class="login-container-loginContent-content-signin-button">
              <button *ngIf="showLogin" class="signin-button" mat-raised-button [disabled]="loginDisabled || !validUsername" (click)="login()">
                <span translate>SIGN_IN</span>
              </button>
              <button *ngIf="showForgetPwd" (click)="gotoLogin()" mat-icon-button class="backArrow"><i class="material-icons" style="font-size: 36px;">arrow_back</i></button>
              <button *ngIf="showForgetPwd" class="reset-button" mat-raised-button [disabled]="loginDisabled" (click)="executeRecaptcha()">
                <span translate>RESET</span>
              </button>
              <app-spinner *ngIf="captchaValidating" [show]="true" size="small"></app-spinner>
              <!--            <div *ngIf="captchaValidationErrorMsg" class="captcha-validation-error-msg" translate>{{captchaValidationErrorMsg}}</div>-->
            </div>
          </div>
          <div class="login-container-loginContent-content-assist" *ngIf="showLogin">
            <div class="login-container-loginContent-content-assist-forgotPass">
              <a translate *ngIf="showLogin" (click)="resetPassword()">RESET_PASSWORD</a>
            </div>
            <div class="login-container-loginContent-content-assist-contactus">
              <a *ngIf="supportContact" href="mailto:{{supportContact}}" class="document-container-contact" translate>CONTACT_US</a>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>

