import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class CsAppApiInterceptor implements HttpInterceptor {

  constructor(private cookieService: CookieService,
              private logger: NGXLogger,
              @Inject('Environment') private environment: any) {
  }

  generateTransactionId() {
    const digits = '0123456789abcdef';
    let n = '';
    for (let i = 0; i < 16; i += 1) {
      const rand = Math.floor(Math.random() * 16);
      n += digits[rand];
    }
    return n;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('/niq-') < 0) {
      return next.handle(req);
    }

    const oauthSsoToken = this.cookieService.get('OAUTHSSOTOKEN');
    const xsrfToken = this.cookieService.get('XSRF-TOKEN')
    this.cookieService.set('OAUTHSSOTOKEN', oauthSsoToken, null, null, this.environment.baseAppURL, false, );
    this.cookieService.set('XSRF-TOKEN', xsrfToken, null, null, this.environment.baseAppURL, false, );

    const cookies: any = this.cookieService.getAll();
    this.logger.debug('[GO] CSAPP interceptor URL:', req.url);
    this.logger.debug('[GO] Cookies:', cookies);

    const newRequest = req.clone({
      headers: req.headers
        .set('X-Requested-With', 'XMLHttpRequest')
        .set('X-NLSN-TNXN-ID', this.generateTransactionId())
        .set('OAUTHSSOTOKEN', oauthSsoToken)
        .set('XSRF-TOKEN', xsrfToken)

    });

    return next.handle(newRequest);
  }
}
