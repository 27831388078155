import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ReportManager } from '../managers/report-manager';
import { UserManager } from '../managers/user-manager';
import { IReport } from '../models/report.type';
import { CategorySort } from '../pipes/category-sort-pipe';
import { DateSort } from '../pipes/date-sort-pipe';
import { GroupBy } from '../pipes/group-by-pipe';
import { NameSort } from '../pipes/name-sort-pipe';
import { QueryPipe } from '../pipes/querypipe';
import { AppConfigService } from '../services/app-config.service';
import { AuthGuardService } from '../services/auth-guard.service';
import { LangService } from '../services/lang.service';
import { ReportService } from '../services/report.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-ap-container',
  templateUrl: './ap-container.component.html',
  styleUrls: ['./ap-container.component.less']
})

export class ApContainerComponent implements OnInit {

  sortingFilters: string[] = ['NAME_ASC', 'NAME_DESC', 'CATEGORY'];

  reports: IReport[] = [];
  pagedReports: IReport[] = [];
  unFilteredReports: IReport[] = [];
  searchText: String;
  sortBySelection: String = 'NAME_ASC';
  reportLoaded = false;
  viewMode: String = 'listview';
  isGrids = false;
  thumbnailEnabled = true;
  reportCategoryOptions: String[] = [];
  category: String;
  currentPage = 0;
  totalPages: number;

  @Input() report: IReport;
  @Output() sendCategories: EventEmitter<any> = new EventEmitter();
  @Output() selectCategory: EventEmitter<any> = new EventEmitter();
  @Inject(DOCUMENT) private document: Document;

  @ViewChild(MatMenuTrigger) categoryMenuTrigger: MatMenuTrigger;

  constructor(private reportService: ReportService,
    private authGuardService: AuthGuardService,
    private router: Router,
    private queryFilter: QueryPipe,
    private nameSort: NameSort,
    private categorySort: CategorySort,
    private dateSort: DateSort,
    private groupBy: GroupBy,
    private langService: LangService,
    private reportManager: ReportManager,
    private userManager: UserManager,
    private appConfigService: AppConfigService,
    private logger: NGXLogger,
     @Inject('Environment') private environment: any) {
  }

  ngOnInit() {
    this.addScrollListener();
    this.userManager.getUserPreference('gridView', 'true', (userPreferences) => {
      if (userPreferences['gridView'] === 'true') {
        this.isGrids = true;
      }
    });

    this.reportLoaded = false;
    this.currentPage = 0;
    this.reportCategoryOptions.push('ALL');
    this.reportCategoryOptions.push('FAVORITE');
    this.category = 'FAVORITE';

    if (this.authGuardService.canActivate()) {
      this.reportManager.getReports(this.getCategorySelected(), 'name', 'asc', (reports) => {
        if (reports === null || reports.length === 0) {
          this.category = this.reportCategoryOptions[0];
          this.reportManager.getReports(this.getCategorySelected(), 'name', 'asc', (categoryReports) => {
            this.updateView(categoryReports);
          }, (err) => {
            this.reportLoaded = true;
          });
        } else {
          this.updateView(reports);
        }
      }, (err) => {
        this.reportLoaded = true;
      });
    }


  }

  updateView(reports) {
    this.searchText = '';
    this.sortBySelection = 'NAME_ASC';
    this.reports = this.reportManager.normalizeReports(reports);
    this.unFilteredReports = this.reports;
    this.getCategoryOptions();
    this.onSort();
    this.reportLoaded = true;
  }

  getCategoryOptions() {
    this.reportService.getReportCategories().subscribe((categories) => {
      categories.forEach(category => {
        this.reportCategoryOptions.push(category);
      });

      this.sendCategories.emit(this.reportCategoryOptions);
      this.selectCategory.emit(this.category);

    }, (error) => {
      this.logger.error('[GO] Error retrieving report categories', error);
    });
  }

  onFavorite(event) {
    this.reportManager.updateFavoriteReport(event, this.reports);
  }

  filterWhileTyping() {
    this.reports = this.unFilteredReports;
    if (this.searchText.length !== 0) {
      this.reports = this.queryFilter.transform(this.reports, this.searchText);
    }

    this.onSort();
  }

  onSort() {
    this.currentPage = 0;
    if (this.sortBySelection === 'NAME_ASC') {
      this.reports = this.nameSort.transform(this.reports, 'ascending');
      this.reports = this.getReportsGroupedByFavorites();
    }
    if (this.sortBySelection === 'NAME_DESC') {
      this.reports = this.nameSort.transform(this.reports, 'descending');
      this.reports = this.getReportsGroupedByFavorites();
    }
    if (this.sortBySelection === 'CATEGORY') {
      this.reports = this.categorySort.transform(this.reports);
      this.reports = this.getReportsGroupedByFavorites();
    }
    if (this.sortBySelection === 'date') {
      this.reports = this.dateSort.transform(this.reports);
      this.reports = this.getReportsGroupedByFavorites();
    }

    this.updatePagedReports();
  }

  onChangeCategory(selectedCategory?) {

    this.reportLoaded = false;

    this.reportManager.getReports(this.getCategorySelected(selectedCategory), 'name', 'asc', (reports) => {
      this.searchText = '';
      this.sortBySelection = 'NAME_ASC';
      this.reports = this.reportManager.normalizeReports(reports);
      this.unFilteredReports = this.reports;
      this.onSort();
      this.reportLoaded = true;
    }, (err) => {
      this.reportLoaded = true;
    });
  }

  gridsMode(isGrids) {
    this.isGrids = isGrids;
  }

  getCategorySelected(selectedCategory?): String {
    if (selectedCategory) {
      this.category = selectedCategory;
    }

    this.selectCategory.emit(this.category);

    if (this.category === this.reportCategoryOptions[0]) {
      return 'all';
    } else if (this.category === this.reportCategoryOptions[1]) {
      return 'favorite';
    }

    return this.category;
  }

  getReportsGroupedByFavorites() {
    const groupedReportsList = this.groupBy.transform(this.reports, 'favorite');
    let groupedReports: IReport[] = [];
    if (groupedReportsList['true']) {
      groupedReports = groupedReports.concat(groupedReportsList['true']);
    }
    if (groupedReportsList['false']) {
      groupedReports = groupedReports.concat(groupedReportsList['false']);
    }
    return groupedReports;
  }

  updatePagedReports() {
    const start = 50 * this.currentPage;
    this.pagedReports = this.reports.slice(start, Math.min(start + 50, this.reports.length));
    this.totalPages = Math.floor((this.reports.length - 1) / 50) + 1;
  }

  private addScrollListener() {
    window.addEventListener(
      'scroll',
      (): void => { this.categoryMenuTrigger.closeMenu(); },
      true);
  }
}
