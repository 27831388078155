<div class="user-vendor-option-dialog-container">
    <h2 class="user-vendor-option-dialog-header" translate>SELECT_USER_VENDOR</h2>
    <mat-dialog-content class="user-vendor-option-dialog-content">

        <div class="form-container">
            <mat-form-field class="form-width">
                <mat-select placeholder="Group" [(ngModel)]="userGroup">
                    <mat-option value="{{option}}" *ngFor="let option of data.userGroups; let i = index;">
                        <span translate>{{option}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="!ssoEnabled" class="form-container">
            <mat-form-field *ngIf="showPasswordInput" class="form-width">
                <input matInput placeholder="Enter your password" data-value="Password" [(ngModel)]="password"
                required name="password" [type]="'password'" (keyup.enter)="switchGroup()">
                <mat-hint translate *ngIf="loginErrorMsg" class="errormsg">{{loginErrorMsg}}</mat-hint>
                <mat-hint translate *ngIf="loginConnectionError"
                    class="login-container-loginContent-content-password-errormsg">UNKNOWN_ERROR</mat-hint>
            </mat-form-field>
        </div>

        <div class="row continue-button-container">
            <button mat-button class="secondary" *ngIf="cancelEnabled()" (click)="closeDialog()">
              <span translate>CANCEL</span>
            </button>
            <button mat-raised-button [disabled]="isLoading" (click)="switchGroup()"
                class="primary">
                <span translate>CONTINUE</span>
            </button>
            <app-spinner class="loader-container" *ngIf="isLoading" [show]="isLoading" size="small"></app-spinner>
        </div>
    </mat-dialog-content>

    <button *ngIf="showPasswordInput" mat-icon-button class="user-vendor-option-dialog-close-button"
        (click)="closeDialog()">
        <mat-icon class="material-icons">cancel</mat-icon>
    </button>
</div>
