import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';
import { InitConfigService } from '../services/init-config.service';
import { AppConfigService } from '../services/app-config.service';
import { UtilsService } from '../utilities/utils';
import { PbSpinnerService } from 'precima-bootstrap/src/components';
import jwtDecode from 'jwt-decode';

declare let heap: any;

@Component({
  selector: 'app-niq-sso',
  templateUrl: './niq-sso.component.html',
  styleUrls: ['./niq-sso.component.less']
})
export class NiqSsoComponent implements OnInit {

  private niqOauthUrl: string;
  private niqAuth0Url: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private appConfig: AppConfigService,
              private utils: UtilsService,
              private authenticationService: AuthenticateService,
              private initConfig: InitConfigService,
              private loadingSpinnerService: PbSpinnerService,
              private logger: NGXLogger) { }

  ngOnInit() {
    this.loadingSpinnerService.loadingMessage = 'Loading';
    this.loadingSpinnerService.addLoadingItemId('niq-sso');

    this.route.fragment.subscribe((fragment) => {
      const parsedFragment = new URLSearchParams(fragment);
      const error = parsedFragment.get('error');
      const errorDescription = parsedFragment.get('error_description');
      const accessToken = parsedFragment.get('access_token');
      const idToken = parsedFragment.get('id_token');
      const code = parsedFragment.get('code');

      if (error) {
        this.logger.error('[GO] Auth error: ' + error + ', description: ' + errorDescription);
        this.router.navigate(['/login'], { preserveFragment: true });
      } else if (accessToken && idToken) {
        this.logger.debug('[GO] Required tokens found. Continuing to app...');

        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('id_token', idToken);
        const token = jwtDecode(idToken);
        const username = token['nickname'];
        localStorage.setItem('username', username);

        heap['track']('Login Success', { username: localStorage.getItem('username'), timestamp: new Date().toTimeString() });
        heap.identify(username);

        this.appConfig.init((config) => {
          heap.addUserProperties({
            'Name': this.utils.getUserFullName(),
            'Email': this.utils.getUserEmail(),
            'Data Access': this.utils.getUserDataAccess(),
            'User Role': this.utils.getUserRole(),
          });
        }, (err) => {
          this.logger.error('[GO] Error fetching app initial config.')
        });

        if (this.initConfig.get().vendorSwitching && code) {
          this.authenticationService.setRefreshToken(code, () => {
            this.goToApp();
          }, () => {
            this.logger.error('[GO] Error fetching refresh token. This will cause switching groups to fail.')
          });
        } else {
          this.goToApp();
        }

      } else {
        this.logger.error('[GO] Required tokens not found. Redirecting to SSO login...');

        this.niqOauthUrl = this.initConfig.get().niqOauthUrl;
        this.niqAuth0Url = this.initConfig.get().niqAuth0Url;
        window.location.href = this.niqAuth0Url;
      }
    })
  }

  private goToApp() {
    // if (!localStorage.getItem('niq_token')) {
    //   this.logger.debug('[GO] NIQ token not found. Redirecting to authorization code URL...');
    //   window.location.href = this.niqOauthUrl;
    // } else {
      this.logger.debug('[GO] All session tokens found. Continuing to dashboard.');
      this.router.navigate(['/dashboard']);
      this.loadingSpinnerService.removeLoadingItemId('niq-sso');
    // }
  }
}
