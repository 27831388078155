import { Injectable } from '@angular/core';
import { ServerService } from './server.service';
import { getApiUrl } from '../utilities/env-utils';
import { IConfiguration } from '../models/iconfiguration';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AppConfigService implements Resolve<any> {

  private configuration: IConfiguration;

  constructor(private serverService: ServerService, private logger: NGXLogger) {
  }

  public init(successCallback, errorCallback) {
    if (this.configuration) {
      successCallback(this.configuration);
      return;
    }
    this.serverService.get(getApiUrl() + '/config', {}).subscribe(config => {
      this.configuration = config;
      successCallback(config);
    }, (error) => {
      this.logger.error('[GO] Error retrieving config', error);
      errorCallback(error);
    }, () => {

    });
  }

  public  get(): IConfiguration {

    if (!this.configuration) {
      this.init((config) => {
        return this.configuration;
      }, (error) => {
        this.logger.error('[GO] Error initializing config', error);
        return null;
      });
    }
    return this.configuration;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.initConfig();
  }

  private initConfig(): Promise<any> {
    return this.serverService.get(getApiUrl() + '/config', {}).toPromise().then(response => {
      this.configuration = response;
      this.logger.debug('[GO] App config loaded', response);
    });
  }
}
