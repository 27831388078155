import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';
import { UserService } from '../services/user.service';
import { AppConfigService } from '../services/app-config.service';
import { InitConfigService } from '../services/init-config.service';
import { UserManager } from '../managers/user-manager';
import { UtilsService } from '../utilities/utils';
import { NGXLogger } from 'ngx-logger';
import { CookieService } from 'ngx-cookie-service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';

declare let reCaptcha: Object;

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.less']
})

export class LoginContainerComponent implements OnInit, OnDestroy, AfterViewInit {

  email: string;
  errorMsg: string;
  resetDisabled = true;
  emailSent = false;
  showForgetPwd = false;
  showLogin = false;
  option: string;
  username: string;
  password: string;
  loginErrorMsg: string = null;
  duplicateLogin = false;
  loginConnectionError = false;
  loginDisabled = false;
  sessionTimeout = false;
  redirectUrl: string;
  private paramSub: any;
  private sub: any;
  private ssoClients: string[];
  supportContact: Object = {};
  reCaptcha;
  captchaValidating = false;
  captchaValidationErrorMsg: string;
  recaptchaSiteKey: string;
  userGroups: String[] = [];
  userGroup: String;
  validUsername = true;
  isVendorSwitchingAllowed: boolean;
  reCaptchaValidationErr = false;
  ssoEnabled = false;

  public focusSettingEventEmitter = new EventEmitter<boolean>();
  @ViewChild('logoutFrame') logoutFrame: ElementRef;
  @ViewChild('usernameInput') usernameInput: ElementRef;
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('captchaRef') captchaRef;

  constructor(private authenticationService: AuthenticateService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private userManager: UserManager,
    private appConfig: AppConfigService,
    private initConfig: InitConfigService,
    private utils: UtilsService,
    private logger: NGXLogger,
    private cookieService: CookieService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    @Inject('Environment') public environment: any,
    @Inject('Constants') private constants: any) {
  }

  ngOnInit() {
    this.recaptchaSiteKey = this.initConfig.get().recaptchaSiteKey;
    this.ssoEnabled = this.initConfig.get().ssoEnabled;

    this.route.fragment.subscribe((fragment) => {
      const parsedFragment = new URLSearchParams(fragment);

      if (parsedFragment.get('error')) {
        this.loginErrorMsg = 'INCORRECT_USERNAME_PASSWORD';
        this.authenticationService.removeUserData();
      }
    });

    this.sub = this.route.params.subscribe(params => {
      this.option = params['option'];

      this.errorMsg = '';
      this.showLogin = false;
      this.sessionTimeout = false;
      this.showForgetPwd = false;
      this.email = '';
      this.username = '';
      this.password = '';
      this.captchaValidationErrorMsg = '';
      this.resetDisabled = true;
      this.loginDisabled = false;
      this.emailSent = false;
      this.redirectUrl = '';
      this.ssoClients = [];
      this.captchaValidating = false;

      if (!this.option) {
        this.showLogin = true;
      } else if (this.option.toString() === 'password-reset') {
        this.showForgetPwd = true;
      } else if (this.option.toString() === 'session-timeout') {
        this.sessionTimeout = true;
        this.showLogin = true;
      }

      this.supportContact = this.utils.checkSupportAccess();

      if (this.authenticationService.isAuthenticated()) {
        this.navigateToApp();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.focusSettingEventEmitter.emit(true);
    });

    this.checkWhitespace();
  }

  onChange(newValue) {
    if (newValue.includes('@')) {
      this.validUsername = false;
    } else {
      this.validUsername = true;
    }
  }

  hideReCaptchaValidErr() {
    this.reCaptchaValidationErr = false;
  }

  /**
   *  Execute reCaptcha v3 service to obtain a response token
   */
  executeRecaptcha() {
    this.reCaptchaV3Service.execute(this.recaptchaSiteKey, 'pwdreset', (token) => {
      this.captchaResolved(token); // send token for validation
    });
  }

  /**
   * Resolve response obtained from reCpatcha v3 service
   * First, the response is validated on the backend. if backend successfully validates recaptcha, a reset email will be sent to the user.
   */
  captchaResolved(captchaResponse) {
    if (!captchaResponse) { return; }

    this.captchaValidating = true;
    this.errorMsg = '';
    this.authenticationService.validateCaptchaToken(captchaResponse).subscribe(res => {
      this.resetDisabled = false;
      this.captchaValidating = false;
      this.sendResetEmail();
    }, error => {
      this.reCaptcha = '';
      this.captchaValidating = false;
      this.reCaptchaValidationErr = true;
      this.logger.error('[GO] Error validating captcha token', error);
    });
  }

  login() {
    if (this.ssoEnabled) {
      this.loginErrorMsg = 'LOGIN_DISABLED_SSO';
      return;
    }

    this.loginDisabled = true;
    this.loginErrorMsg = null;
    this.duplicateLogin = false;
    this.loginConnectionError = false;

    this.authenticationService.login(this.username.toLowerCase(), this.password, null, () => {
      this.ssoClients = this.utils.getSsoClients();
      if (this.utils.isSsoAllowed()) {
        this.authenticationService.loginForClient(this.ssoClients, this.username.toLowerCase(), this.password, (authResult, username) => {
          this.logger.debug('[GO] Auth tokens saved for SSO client');
        }, (error) => {
          this.logger.error('[GO] Auth tokens not saved for SSO client');
        });
      }

        this.navigateToApp();
    }, (error) => {
      if (error.description === 'duplicate login') {
        this.duplicateLogin = true;
      } else if (error.description === 'user is blocked') {
        this.loginErrorMsg = 'USER_BLOCKED_SUPPORT';
      } else if (error.description === 'Please change your password') {
        this.loginErrorMsg = 'PASSWORD_EXPIRED';
      } else if (error.statusCode === 401 || error.description === 'Wrong email or password.' || error.code === 'invalid_grant') {
        this.loginErrorMsg = 'INCORRECT_USERNAME_PASSWORD';
      } else {
        this.loginConnectionError = true;
      }

      this.loginDisabled = false;
    });
  }

  private navigateToApp() {
    this.loginDisabled = false;

    /*if (window.location.href.indexOf('riteaid.retail.uat.connect.nielseniq.io') >= 0 && !localStorage.getItem('niq_token')) {
      //window.location.href = 'https://uat.ng2.answers.nielseniq.io/auth/oauth/v2/authorize?response_type=code&client_id=uat_connect_precima_poc';
    } else*/
    if (this.redirectUrl) {
      window.location.href = this.redirectUrl + '?id_token=' + localStorage.getItem('id_token');
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  private sendResetEmail() {
    this.errorMsg = '';

    if (!this.constants.emailRegex.test(this.email)) {
      this.errorMsg = 'PASSWORD_RESET_WRONG_EMAIL_FORMAT';
      return;
    }

    if (this.resetDisabled) {
      this.errorMsg = 'PASSWORD_RESET_DISABLED';
      return;
    }

    this.emailSent = true;
    this.resetDisabled = true;
    this.showForgetPwd = false;

    if (this.environment.runLocal) {
      return;
    }

    this.userService.validateUser('mail', this.email).subscribe(res => {
      if (res) {
        this.sendEmail();
      }
    }, (error) => {
      this.logger.error('[GO] Error validating user', error);
    });
  }

  private sendEmail() {

    const data = {
      'client_id': this.initConfig.get().auth0ClientId,
      'email': this.email,
      'password': '',
      'connection': this.initConfig.get().auth0PasswordResetConnection
    };

    const auth0 = this.utils.getWebAuthInstance(null);
    auth0.changePassword(data, (error, resp) => {
      if (error) {
        this.logger.error('[GO] Error changing password', error);
        this.errorMsg = 'PASSWORD_RESET_ERROR';
      }
    });
  }

  public resetPassword() {
    this.router.navigate(['/login', 'password-reset'], { queryParams: this.getRedirectQueryString() });
  }

  public gotoLogin() {
    this.router.navigate(['/login'], { queryParams: this.getRedirectQueryString() });
  }

  private getRedirectQueryString() {
    const queryObj = {};
    if (this.redirectUrl) {
      queryObj['originUrl'] = this.redirectUrl;
    }

    return queryObj;
  }

  private checkWhitespace() {
    if (document.getElementById('passwordInput')) {
      document.getElementById('passwordInput').addEventListener('keydown', function (e) {
        const length = (<HTMLInputElement>document.getElementById('passwordInput')).value.length;
        if (length === 0 && e.which === 32) {
          e.preventDefault();
        }
      });
    }
  }
}
