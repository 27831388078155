import { environment } from '../../environments/environment';
import { clientCodes } from '../../environments/client-codes';

export function getClientFromUrl() {
  const url = window.location.href;
  return url.substring(url.indexOf('//') + '//'.length, url.indexOf('.'));
}

export function getApiUrl() {
  const url = window.location.href;

  if (url.indexOf('localhost') >= 0) {
    return environment.apiEndpoint;
  }

  return getApiGatewayUrl() + '/go/api';
}

export function getApiGatewayUrl() {
  const url = window.location.href;
  const urlWithoutScheme = url.substring(url.indexOf('//') + '//'.length);
  const host = urlWithoutScheme.substring(0, urlWithoutScheme.indexOf('/'));

  return `https://gateway.${host}`;
}

export function getClientCode() {
  const url = window.location.href;

  if (url.indexOf('localhost') >= 0) {
    return environment.client;
  }

  const urlWithoutScheme = url.substring(url.indexOf('//') + '//'.length);
  const client = urlWithoutScheme.substring(0, urlWithoutScheme.indexOf('.'));

  return clientCodes[client];
}
