<div class="dashboard">
  <div class="dashboard-content">
    <div class="welcome-board">
      <app-welcome-board></app-welcome-board>
    </div>

    <div class="apps-wrapper">
      <div class="apps">
        <div class="label" translate *ngIf="apps.length > 0">TOOLS_TITLE</div>
        <div class="cells">
          <div *ngFor="let app of apps; let i = index;" class="cell">
            <div class="cell-content">
              <a href={{app.url}} (click)="emitApp(i,app)">
                <div class="app-icon {{ app.code }}"></div>
                <div class="app-name">{{ app.label }}</div>
              </a>
            </div>
          </div>
        </div>

        <div *ngIf="environment.client !== 'demo' && hasTraining">
          <div class="label support" translate>SUPPORT</div>
          <div class="cells">
            <div *ngFor="let app of supportOptions; let i = index;" class="cell">
              <div class="cell-content">
                <a href={{app.url}} (click)="emitApp(i, app)" target="{{app.code === 'training' ? '_blank' : '_self'}}">
                  <div class="app-icon {{ app.code }}"></div>
                  <div class="app-name">{{ app.label }}</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
