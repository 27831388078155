import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { getApiUrl } from '../utilities/env-utils';
import { ServerService } from './server.service';

@Injectable({
  providedIn: 'root'
})
export class NielsenService {

  constructor(private httpClient: HttpClient,
              private serverService: ServerService,
              private logger: NGXLogger) { }

  public getTokens() {
    return this.serverService.get(getApiUrl() + '/niq-auth/v5/tokens', {});
  }
}
