export enum AppCode {

    /** Normal Modules */
    Reports = 'reports',

    /** Lazy Modules */
    DP = 'dp',
    Promo = 'promo',
    Assort = 'assortment',
    Insights = 'insights',
    SC = 'sc',
    PriceNg = 'pricing',
    RetailDashboard = 'retail-dashboard',
    NielsenConnect = 'nielsen-connect',
    MyDashboard = 'my-dashboard',
    Dashboard = 'dashboard',

    /** External Apps */
    AA = 'aa',
    CI = 'cia',
    CPL = 'cpl',
    Hub = 'hub',
    Price = 'price',
    Segmentation = 'segmentation',
    Offer = 'offer-ads',
    ActivateInsights = 'insights-ci',

    /** Supports */
    Support = 'support',
    Training = 'training',
    Contact = 'contact',

    Preferences = 'preferences',
    SwitchAccount = 'switch-account',
    SignOut = 'sign-out',

    Home = 'home'
}

export const APPS_WITH_SIDENAV_ARROW = [
  AppCode.Reports,
  AppCode.Promo,
  AppCode.Assort,
  AppCode.Insights,
  AppCode.SC,
  AppCode.PriceNg,
  AppCode.DP,
  AppCode.RetailDashboard
];
