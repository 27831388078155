import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UtilsService } from '../utilities/utils';
import { AppConfigService } from './app-config.service';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { UserService } from './user.service';
import { AppCode } from '../models/appOption/AppCode';

@Injectable()
export class AppGuardService {

  constructor(private router: Router,
              private utils: UtilsService,
              private appConfig: AppConfigService,
              private userService: UserService,
              private logger: NGXLogger) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const appCode = route.data.appCode;

    return this.userService.hasAppAccess(appCode).then(appAccess => {
      if (appAccess) {
        this.logger.debug('[GO] User has access to app:', appCode);
        return true;
      }

      return this.userService.hasAppAccess(AppCode.MyDashboard).then(personalizedDashboardAccess => {
        if (personalizedDashboardAccess) {
          this.logger.debug('[GO] User does not have access to app:', appCode);
          this.logger.debug('[GO] Redirecting to personalized dashboard');
          return this.router.navigate(['main/my-dashboard']);
        } else if (appCode !== AppCode.Dashboard) {
          this.logger.debug('[GO] User does not have access to app:', appCode);
          this.logger.debug('[GO] Redirecting to dashboard');
          return this.router.navigate(['main/dashboard']);
        }

        this.logger.debug('[GO] User has access to dashboard');
        return true;
      });
    });
  }
}
