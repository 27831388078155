<!-- grid mode -->
<div id="gridView" class="report-grid" (click)="clickReport()" *ngIf="isGrids" [style.border-color]="btmBorderColor" [ngClass]="{'tile-favo':isFavorite,'tile-favo-not':!isFavorite}">
    <div class="row report-grid-favo-icon-container">
        <i class="report-grid-favo-icon linear-icon linear-icon--star" (click)="optionFavorited($event)" (mouseenter)="onMouseOver()"
            (mouseleave)="onMouseLeave()"><span class="tooltiptext-favo">{{ isFavorite ? ('TOOLTIP_UNFAVORITE' | translate ) : 'TOOLTIP_FAVORITE' | translate }}</span></i>
            <!-- <div title="{{ 'CLOSE_SECONDARY_SIDENAV' | translate }}">hello</div> -->

    </div>

    <p class="report-grid-title" title="{{report.name | reportNameFormatPipe}}">{{report.name | reportNameFormatPipe}}</p>

    <p class="report-grid-category">
        <img class="report-grid-category-img" src="{{imagePath}}" *ngIf="thumbnailsEnabled">
        <span class="report-grid-category-text">{{report.category}}</span>
    </p>
</div>

<!-- list mode -->
<table *ngIf="!isGrids" class="report-list">
    <tr id="listView" (click)="clickReport()" valign='center'>
        <td class="report-list-title">
            <i class="linear-icon linear-icon--star" [ngClass]="{'half-visible': (!isFavorite),'visible':isFavorite}" (click)="optionFavorited($event)"
                (mouseenter)="onMouseOver()" (mouseleave)="onMouseLeave()"></i>
            {{report.name | reportNameFormatPipe}}
        </td>
        <td class="report-list-category-img">
            <img src="{{imagePath}}" *ngIf="thumbnailsEnabled">
        </td>
        <td class="report-list-category">
            {{report.category}}
        </td>
    </tr>
</table>