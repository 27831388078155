import { EventEmitter, Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { UtilsService } from '../utilities/utils';
import { UserManager } from '../managers/user-manager';
import { ServerService } from './server.service';
import { AppConfigService } from './app-config.service';
import { InitConfigService } from './init-config.service';
import { getApiUrl } from '../utilities/env-utils';
import { NGXLogger } from 'ngx-logger';
import jwtDecode from 'jwt-decode';

declare let heap: any;
declare let ChurnZero: any;

@Injectable()
export class AuthenticateService {

  public applogout: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router,
    @Inject('Environment') private environment: any,
    private userManager: UserManager,
    private serverService: ServerService,
    private appConfig: AppConfigService,
    private initConfig: InitConfigService,
    private utils: UtilsService,
    private logger: NGXLogger) {
  }

  public setRefreshToken(code: string, successCallback, errorCallback) {

    this.serverService.rawGet(getApiUrl() + '/sso-auth/tokens?code=' + code, {}).subscribe(response => {
      this.logger.debug('[GO] Refresh token call success.........');
      this.logger.debug('[GO] authResult:' + JSON.stringify(response));
      this.saveRefreshToken(response);
      successCallback();
     }, error => {
      this.logger.error('[GO] Error exchanging authorization code for refresh token:' + JSON.stringify(error));
      errorCallback(error);
      return;
    });
  }

  public silentLogin(username, group, successCallback, errorCallback): void {
    const refreshTokenOption = this.utils.getRefreshTokenOptionInstance(group, localStorage.getItem('refresh_token'));
    this.utils.getWebAuthInstance(group).client.oauthToken(refreshTokenOption, (err, authResult) => {
      if (err) {
        errorCallback(err);
        return;
      }
      if (authResult && authResult.idToken && authResult.accessToken) {
        this.saveUserParams(authResult, username, group);
        successCallback();

        heap['track']('Login Success', { username: localStorage.getItem('username'), timestamp: new Date().toTimeString() });
        heap.identify(username);

        this.appConfig.init((config) => {
          heap.addUserProperties({
            'Name': this.utils.getUserFullName(),
            'Email': this.utils.getUserEmail(),
            'Data Access': this.utils.getUserDataAccess(),
            'User Role': this.utils.getUserRole(),
          });
        }, (err) => {
          errorCallback();
        });
      }
    });
  }

  public login(username: string, password: string, group, successCallback, errorCallback): void {
    // this.cookieService.deleteAll('/');
    if (!this.environment.runLocal) {
      this.utils.getWebAuthInstance(group).client.login({
        realm: this.initConfig.get().adConnection,
        username,
        password
      }, (err, authResult) => {
        if (err) {
          errorCallback(err);
          return;
        }
        if (authResult && authResult.idToken && authResult.accessToken) {
          this.saveUserParams(authResult, username, group);
          // ga('set', 'dimension1', new Date().toISOString());
          // ga('set', '&uid', username);
          heap['track']('Login Success', { username: localStorage.getItem('username'), timestamp: new Date().toTimeString() });
          heap.identify(username);
          this.appConfig.init((config) => {
            successCallback();
            heap.addUserProperties({
              'Name': this.utils.getUserFullName(),
              'Email': this.utils.getUserEmail(),
              'Data Access': this.utils.getUserDataAccess(),
              'User Role': this.utils.getUserRole(),
            });
          }, (err) => {
            errorCallback();
          });
        }
      });
    } else {
      this.serverService.rawGet(getApiUrl() + '/create-token', { username, password }).subscribe(authResult => {
        this.saveUserParams(authResult, username, group);
        this.appConfig.init((config) => {
          successCallback();
        }, (err) => {
          errorCallback();
        });
      }, err => {
        errorCallback(JSON.parse(err._body));
      });
    }
  }

  public loginForClient(ssoClients: string[], username: string, password: string, successCallback, errorCallback): void {
    ssoClients.forEach(ssoClient => {
      if (!this.initConfig.get().clientUserPrefix.startsWith(ssoClient)) {
        if (this.environment.runLocal) {
          const authResult = { idToken: localStorage.getItem('id_token'), accessToken: localStorage.getItem('access_token') };
          this.saveUserParamsForClient(ssoClient, authResult, username);
          successCallback(authResult, username);
        } else {
          this.utils.getWebAuthInstanceForSsoClient(ssoClient).client.login({
            realm: this.initConfig.get().adConnection,
            username,
            password
          }, (err, authResult) => {
            if (err) {
              errorCallback(err);
              return;
            }
            if (authResult && authResult.idToken && authResult.accessToken) {
              this.saveUserParamsForClient(ssoClient, authResult, username);
              successCallback(authResult, username);
            }
          });
        }
      }
    });
  }

  public validateCaptchaToken(token) {
    return this.serverService.rawGet(getApiUrl() + '/recaptcha-validation', { token: token });
  }

  public isAuthenticated(): boolean {
    // Check whether the id_token is expired or not
    const helper = new JwtHelperService();
    return !helper.isTokenExpired(localStorage.getItem('id_token'));
  }

  public logout(url): void {
    if (this.initConfig.get().churnZeroEnabled) {
      ChurnZero.push(['stop']);
    }

    this.router.navigate([url]);
  }

  private saveRefreshToken(authResult): void {
    localStorage.setItem('access_token', authResult['access_token']);
    localStorage.setItem('id_token', authResult['id_token']);
    localStorage.setItem('refresh_token', authResult['refresh_token']);

    const token = jwtDecode(authResult['id_token']);
    const index = token['sub'].lastIndexOf('|');
    const userId = token['sub'].substring(index + 1);
    localStorage.setItem('userFullId', token['sub']);
    localStorage.setItem('userId', userId);
    const username = token['nickname'];
    localStorage.setItem('username', username);
  }

  private saveUserParams(authResult, username: string, group): void {
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    if (authResult.refreshToken) {
      localStorage.setItem('refresh_token', authResult.refreshToken);
    }
    localStorage.setItem('username', username);

    const token = jwtDecode(authResult.idToken);
    const index = token['sub'].lastIndexOf('|');
    const userId = token['sub'].substring(index + 1);
    localStorage.setItem('userFullId', token['sub']);
    localStorage.setItem('userId', userId);
    if (group && !group.includes('null')) {
      localStorage.setItem('userGroup', username + ',' + group);
    }
  }

  private saveUserParamsForClient(ssoClient, authResult, username: string): void {
    localStorage.setItem('access_token_' + ssoClient, authResult.accessToken);
    localStorage.setItem('id_token_' + ssoClient, authResult.idToken);
    if (authResult.refreshToken) {
      localStorage.setItem('refresh_token', authResult.refreshToken);
    }
    localStorage.setItem('username_' + ssoClient, username);
  }

  public removeUserData() {
    const userGroup = localStorage.getItem('userGroup');
    localStorage.clear();
    if (userGroup && !userGroup.includes('null')) {
      localStorage.setItem('userGroup', userGroup);
    } else {
      localStorage.removeItem('userGroup');
    }
  }
}
