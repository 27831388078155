import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, Inject, OnChanges } from '@angular/core';
import { AppOption, getPathBlack, getPathWhite } from '../../models/appOption/AppOption';
import { GlobalService } from '../../services/global.service';
import { NGXLogger } from 'ngx-logger';

export enum MenuMode {
  List = 'list',
  Grid = 'grid',
  Pop = 'pop'
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit, OnChanges {
  @Input() apps = [] as AppOption[];
  @Input() opened = true;
  @Input() mode = MenuMode.Grid;
  @Input() appsNumber = 0;
  @Input() additionalLinksNumber = 0;
  @Input() hasTraining = false;
  @Output() onSelect = new EventEmitter<any>();
  @Output() onMouseLeavePopMenu = new EventEmitter<any>();
  @Output() onSupportMenuClose = new EventEmitter<any>();
  @Output() onMouseoverChangeFill = new EventEmitter<any>();

  @Input() menuHeight = '';
  @Input() menuHeightMax = '';
  @Input() hover = false;
  supportIndex = 0;

  constructor(private globalService: GlobalService,
              @Inject('Environment') private environment: any,
              private logger: NGXLogger) { }

  ngOnInit() {
    this.setMenuMaxHeight();
    this.supportIndex = this.apps.length;
    this.hover = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setMenuMaxHeight();
    this.supportIndex = this.apps.length;
    this.logger.debug('[GO] Apps', this.apps);

    if (this.apps.length > 0) {
      const selectedIndex = this.apps.findIndex((option) => option.selected);
      if (selectedIndex > 0) { this.changeColor({hover: false, option: this.apps[selectedIndex]}); }
    }
  }

  select($event: any) {
    this.apps.map((app, index) => { if (index !== $event.index) { app.iconPath = getPathWhite(app.iconPath); }});
    this.onSelect.emit($event);
  }

  mouseLeave($event) {
    if ($event['movementX'] === 0 && $event['movementY'] === 0) { return; }
    this.onMouseLeavePopMenu.emit($event);
  }

  setMenuMaxHeight() {
    switch (this.mode) {
      case MenuMode.List:
        this.menuHeightMax = ((this.appsNumber + this.additionalLinksNumber) * 50).toString();
        this.menuHeight = ((this.appsNumber + this.additionalLinksNumber) * 50).toString();
        break;

      case MenuMode.Grid:
        this.menuHeight = (Math.ceil((this.appsNumber + this.additionalLinksNumber) / 2) * 55).toString();
        this.menuHeightMax = (Math.ceil((this.appsNumber + this.additionalLinksNumber) / 2) * 55).toString();
        break;

      case MenuMode.Pop:
        break;

      default:
        break;
    }
  }

  changeColor($event: any) {
    this.hover = $event.hover;

    if ( this.hover || ($event.option && $event.option.selected) ) {
      $event.option.iconPath = getPathBlack($event.option.iconPath);
    } else {
      $event.option.iconPath = getPathWhite($event.option.iconPath);
    }
    this.onMouseoverChangeFill.emit($event);
  }

  get showLabel() {
    return this.opened || (!this.opened && this.mode != MenuMode.List);
  }
}
