import { Injectable } from '@angular/core';
import { InitConfiguration } from '../models/init-configuration';
import { getApiUrl } from '../utilities/env-utils';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class InitConfigService {

  private configuration: InitConfiguration;

  constructor(private httpClient: HttpClient, private logger: NGXLogger) {
  }

  public load(): Promise<InitConfiguration> {
    if (!getApiUrl()) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.httpClient.get<InitConfiguration>(`${getApiUrl()}/config/login`).subscribe(response => {
        this.configuration = response;
        this.logger.debug('[GO] Login configuration loaded', this.configuration);
        resolve(this.configuration);
      });
    });
  }

  public get(): InitConfiguration {
    return this.configuration;
  }
}
